import React, { useEffect, useState } from "react";
import {
  CustomCard,
  CustomUserCard,
} from "components/utils/MaterialUICustomizedComponents";
import "./home.css";
import { Typography } from "@material-ui/core";
import firebase, { db } from "config/Firebase";
import { Row } from "components/utils/MaterialUICustomizedComponents";
import { Column } from "components/utils/MaterialUICustomizedComponents";
import { CustomCardSkeleton } from "components/utils/MaterialUICustomizedComponents";
import { CustomUserCardSkeleton } from "components/utils/MaterialUICustomizedComponents";

const Home = () => {
  const [popularMemes, setPopularMemes] = useState([]);
  const [popularUsers, setPopularUsers] = useState([]);
  const [latestMemes, setLatestMemes] = useState([]);
  const [popularMemesLoaded, setPopularMemesLoaded] = useState(false);
  const [popularUsersLoaded, setPopularUsersLoaded] = useState(false);
  const [latestMemesLoaded, setLatestMemesLoaded] = useState(false);
  useEffect(() => {
    setPopularMemesLoaded(false);
    setPopularUsersLoaded(false);
    setLatestMemesLoaded(false);
    const fetchPopular = () => {
      return db
        .collection("memes")
        .orderBy("views", "desc")
        .limit(3)
        .onSnapshot((docs) => {
          const pm = [];
          docs.forEach((doc) => pm.push(doc.data()));
          setPopularMemes(pm);
          setPopularMemesLoaded(true);
        });
    };
    const fetchLatest = () => {
      const tsToMillis = firebase.firestore.Timestamp.now().toMillis();
      const compareDate = new Date(tsToMillis - 24 * 60 * 60 * 1000);
      return db
        .collection("memes")
        .orderBy("time", "desc")
        .where("time", ">", compareDate)
        .onSnapshot((docs) => {
          const lm = docs.docs.map((doc) => doc.data());
          setLatestMemes(lm);
          setLatestMemesLoaded(true);
        });
    };
    const fetchPopularUsers = () => {
      return db
        .collection("users")
        .orderBy("likes", "desc")
        .limit(3)
        .onSnapshot((docs) => {
          setPopularUsers(docs.docs.map((doc) => doc.data()));
          setPopularUsersLoaded(true);
        });
    };
    const unsubPopular = fetchPopular();
    const unsubLatest = fetchLatest();
    const unsubPopularUsers = fetchPopularUsers();
    return () => {
      unsubPopular();
      unsubLatest();
      unsubPopularUsers();
    };
  }, []);
  return (
    <div className="mt-4">
      <div className="container">
        <div className="mb-4">
          <Typography variant="h1">Trending Memes</Typography>
          <Row className="py-1">
            {popularMemesLoaded ? (
              popularMemes.map((el) => (
                <Column className="px-sm-2 py-1 px-1 position-relative" lg={4}>
                  <CustomCard {...el} />
                </Column>
              ))
            ) : (
              <>
                <Column className="px-sm-2 py-1 px-1 position-relative" lg={4}>
                  <CustomCardSkeleton />
                </Column>
                <Column className="px-sm-2 py-1 px-1 position-relative" lg={4}>
                  <CustomCardSkeleton />
                </Column>
                <Column className="px-sm-2 py-1 px-1 position-relative" lg={4}>
                  <CustomCardSkeleton />
                </Column>
              </>
            )}
          </Row>
        </div>
        <div className="mb-4">
          <Typography variant="h1">Popular Users</Typography>
          <div>
            <Row className="py-1">
              {popularUsersLoaded ? (
                popularUsers.map((el) => (
                  <Column
                    className="px-sm-2 py-1 px-1 position-relative"
                    lg={4}
                  >
                    <CustomUserCard {...el} />
                  </Column>
                ))
              ) : (
                <>
                  <Column
                    className="px-sm-2 py-1 px-1 position-relative"
                    lg={4}
                  >
                    <CustomUserCardSkeleton />
                  </Column>
                  <Column
                    className="px-sm-2 py-1 px-1 position-relative"
                    lg={4}
                  >
                    <CustomUserCardSkeleton />
                  </Column>
                  <Column
                    className="px-sm-2 py-1 px-1 position-relative"
                    lg={4}
                  >
                    <CustomUserCardSkeleton />
                  </Column>
                </>
              )}
            </Row>
          </div>
        </div>
        <div className="mb-4">
          <Typography variant="h1">Latest Memes</Typography>
          <Row className="py-1">
            {latestMemesLoaded ? (
              latestMemes.map((el) => (
                <Column className="px-sm-2 py-1 px-1 position-relative" lg={4}>
                  <CustomCard {...el} />
                </Column>
              ))
            ) : (
              <>
                <Column className="px-sm-2 py-1 px-1 position-relative" lg={4}>
                  <CustomCardSkeleton />
                </Column>
                <Column className="px-sm-2 py-1 px-1 position-relative" lg={4}>
                  <CustomCardSkeleton />
                </Column>
                <Column className="px-sm-2 py-1 px-1 position-relative" lg={4}>
                  <CustomCardSkeleton />
                </Column>
              </>
            )}
          </Row>
        </div>
      </div>
    </div>
  );
};

export default Home;
