import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Paper,
} from "@material-ui/core";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import React, { useEffect, useState } from "react";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import AlternateEmailSharpIcon from "@material-ui/icons/AlternateEmailSharp";
import { NavLink, useHistory } from "react-router-dom";
import { useAuth } from "context/AuthContext";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import { auth } from "config/Firebase";
import { Helmet } from "react-helmet-async";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  small: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    fontSize: `0.9rem`,
  },
  icon: {
    minWidth: 30,
  },
  main: {
    paddingLeft: theme.spacing(2),
    width: `100%`,
    "&:hover": {
      backgroundColor: theme.palette.action.selected,
      color: theme.palette.primary.main,
    },
  },
  divider: {
    backgroundColor: theme.palette.action.hover,
    color: theme.palette.primary.dark,
    "&:hover": {
      backgroundColor: theme.palette.action.selected,
      color: theme.palette.primary.main,
    },
    "& .MuiListItemIcon-root": {
      color: theme.palette.primary.dark,
    },
    "&:hover .MuiListItemIcon-root": {
      color: theme.palette.primary.main,
    },
  },
}));

const ProfileContainer = ({ children, user: prevUser }) => {
  const classes = useStyles();
  const { activeUser = {} } = useAuth();
  const [user, setUser] = useState({});
  const [isActiveUser, setIsActiveUser] = useState(false);
  useEffect(() => {
    setUser(prevUser || activeUser || {});
  }, [activeUser, prevUser]);
  useEffect(() => {
    if (activeUser.uid && user.uid)
      setIsActiveUser(Boolean(activeUser.uid === user.uid));
  }, [activeUser, user]);

  const { push } = useHistory();
  return (
    <div className="py-5">
      <Helmet>
        <title>{user.username}</title>
        <meta property="og:title" content={user.username} />
        <meta property="og:url" content={window.location.host} />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content={user.username} />
        <meta name="twitter:description" content={`Likes: ${user.likes}`} />
        <meta name="twitter:image" content={user.dp} />
        <meta property="og:description" content={`Likes: ${user.likes}`} />
        <meta property="og:type" content="profile" />
        <meta property="og:image" content={user.dp} />
      </Helmet>
      <div className="container">
        <div className="row">
          {isActiveUser && (
            <div className="col-lg-3 py-5 pe-5">
              <List
                component={Paper}
                style={{ top: 80 }}
                elevation={2}
                className="py-0 position-sticky"
              >
                <ListItem className="fw-bold h6 mb-0 py-3">My Account</ListItem>
                <ListItem
                  component={NavLink}
                  to={`/user/${user.uid}`}
                  exact
                  activeClassName={classes.divider}
                  button
                  className={"my_link " + classes.main}
                >
                  <ListItemIcon className={classes.icon}>
                    <AccountCircleIcon />
                  </ListItemIcon>
                  <ListItemText primary="Profile" />
                </ListItem>
                <ListItem
                  component={NavLink}
                  to="/account/emails"
                  exact
                  activeClassName={classes.divider}
                  button
                  className={"my_link " + classes.main}
                >
                  <ListItemIcon className={classes.icon}>
                    <AlternateEmailSharpIcon />
                  </ListItemIcon>
                  <ListItemText primary="Change Email" />
                </ListItem>
                <ListItem
                  component={NavLink}
                  to="/account/password"
                  exact
                  activeClassName={classes.divider}
                  button
                  className={"my_link " + classes.main}
                >
                  <ListItemIcon className={classes.icon}>
                    <VpnKeyIcon />
                  </ListItemIcon>
                  <ListItemText primary="Change Password" />
                </ListItem>
                <ListItem
                  onClick={async () => {
                    await auth.signOut();
                    push("/");
                  }}
                  button
                  className={"my_link align-self-end " + classes.main}
                >
                  <ListItemIcon className={classes.icon}>
                    <PowerSettingsNewIcon />
                  </ListItemIcon>
                  <ListItemText primary="Logout" />
                </ListItem>
              </List>
            </div>
          )}
          <div className={`col-lg-${isActiveUser ? 9 : 12}`}>
            <Paper className="container-fluid position-relative emp-profile py-5 my-5">
              {children}
            </Paper>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileContainer;
