import React from "react";

const Loader = ({fullPage=false,blurred = false}) => {
  const styles = {
    backgroundColor: `rgba(255,255,255,0.6)`,
    backdropFilter: `blur(2px)`
  }
  return (
    <div style={{...(blurred && styles)}} className={`loader_wrapper ${!fullPage ? "section_covered" : ""}`}>
      <ul className="loader">
        <li className="center" />
        <li className="item" style={{ "--i": 1 }} />
        <li className="item" style={{ "--i": 2 }} />
        <li className="item" style={{ "--i": 3 }} />
        <li className="item" style={{ "--i": 4 }} />
        <li className="item" style={{ "--i": 5 }} />
        <li className="item" style={{ "--i": 6 }} />
        <li className="item" style={{ "--i": 7 }} />
        <li className="item" style={{ "--i": 8 }} />
      </ul>
    </div>
  );
};

export default Loader;
