import React, { createContext, createRef, useContext } from 'react';
import Input from 'components/utils/Input';
// import Editor from "jodit-react";
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputAdornment from '@material-ui/core/InputAdornment';
// import DragFile from 'components/utils/DragFile';
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Typography } from '@material-ui/core';
const FunctionsContext = createContext();

export const useFunctions = ()=> useContext(FunctionsContext);

const FunctionsContextProvider = ({children}) => {
  class Refs {
    constructor(values=[],tags=[]) {
      values.forEach((el) => {
        this[el] = {
          ref: createRef(),
          placeholder: el
        };
      });
      tags.forEach((el) => {
        this[el.name] = {
          ref: createRef(),
          placeholder:  el.name,
          ...el
        };
      });
      
    }
  }
  class GetValues {
    constructor(values = {}) {
      Object.entries(values).forEach((el) => {
        this[el[0]] = `${el[1].ref.current.value}${el[1]?.type?.unit||``}`;
      });
    }
  }

  const showEditors = (editorRefs={},active) =>
    Object.entries(editorRefs)?.map((el, i) => (
      <div key={i} className="col-12 py-2">
        <p style={{ textTransform: `uppercase` }}>
          {el[1].placeholder.split("_").join(" ")}:
        </p>
        {/* <Editor
          ref={el[1].ref}
          required
          value={active[el[0]]}
          config={{
            editorCssClass: el[0],
            placeholder: el[1].placeholder.split("_").join(" "),
          }}
        /> */}
      </div>
    ));
  const showTags = (tagRefs={},active,required=true,cols) =>
    Object.entries(tagRefs)?.map((el, i) => (
      <div key={i} className={`col-lg-${cols}`}>
        <label htmlFor={el[1].placeholder} className="text-capitalize fw-bolder"> {el[1].placeholder.split("_").join(" ")}: </label>
        <Input
          ref={el[1].ref}
          required={required}
          {...el[1]}
          id={el[1].placeholder}
          defaultValue={active[el[0]]}
          placeholder={el[1].placeholder.split("_").join(" ")}
          type={el[1]?.type?.name}
          step=".01"
          InputProps={{endAdornment: <InputAdornment className="text-capitalize fw-bolder" position="end">{el[1]?.type?.unit}</InputAdornment>,...el[1].InputProps}}
        />
      </div>
    ));
  const showImages = (images=[],setImages=()=>{},active={},docRef) =>
    images.map((el, i) => (
      <div key={i} className={"col-lg-6"}>
        <Typography variant="h6" className="text-capitalize fw-bold mt-3"> {el.name.split("_").join(" ")} </Typography>
        {/* <DragFile size={el.size} type={el.type} docRef={docRef} name={el.name} active={active[el.name]?.map((preview,i)=>({...preview,database: true,i}))} setImages={(images)=> setImages(prev=>({...prev,[el.name]: images}))} /> */}
      </div>
    ));
  const showRadios = (radios=[],radioValues,setRadios,cols) =>
    radios.map((el, i) => (
      <div key={i} className={`col-lg-${cols}`}>
         <FormControl className="d-flex flex-row text-capitalize align-items-center" component="fieldset">
          <FormLabel className="w-auto me-2 text-secondary" component="legend">{el.name.split("_").join(" ")}:</FormLabel>
          <RadioGroup row aria-label={el.name} onChange={e=> setRadios(n=> ({...n,[el.name]: e.target.value}))} value={radioValues[el.name] || ""} name={el.name}>
            {el.options.map((name)=>{
              return<FormControlLabel value={name} control={<Radio required color="primary" />} label={name} />
            })}
          </RadioGroup>
        </FormControl>
      </div>
    ));
  const showSelect = (selects=[],selectValues,setSelects) =>
    selects.map((el, i) => (
      <div key={i} className="col-lg-6">
        <label htmlFor={el.name} className="text-capitalize fw-bolder"> {el.name.split("_").join(" ")} </label>
        <div className="field">
          <Select displayEmpty required
            id={el.name}
            className="field_input text-capitalize"
            value={selectValues[el.name]?.name || ""}
            onChange={e=> setSelects(n=> ({...n,[el.name]: {name: e.target.value}}))}
          >
            <MenuItem disabled value="">
              <em className="text-capitalize">{el.name.split("_").join(" ")}</em>
            </MenuItem>
            {
              el.options.map(el=><MenuItem className="text-capitalize" value={el}> {el} </MenuItem>)
            }
          </Select>
        </div>
        <div className="w-100"/>
        {selectValues[el.name]?.name=== "number"&&<div className="field">
          <Select displayEmpty required
            className="field_input text-capitalize"
            value={selectValues[el.name].unit || ""}
            onChange={e=> setSelects(n=> ({...n,[el.name]: {...n[el.name],unit: e.target.value}}))}
          >
            <MenuItem disabled value="">
              <em className="text-capitalize">Unit</em>
            </MenuItem>
            {
              el.units.map(el=><MenuItem className="text-capitalize" value={el}> {el} </MenuItem>)
            }
          </Select>
        </div>}
      </div>
    ));
  const value = {Refs,GetValues,showRadios,showImages,showTags,showSelect,showEditors}
  return (
    <FunctionsContext.Provider {...{value}}>
      {children}
    </FunctionsContext.Provider>
  );
};

export default FunctionsContextProvider;