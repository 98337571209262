import React, { useEffect, useState } from "react";
import { useAuth } from "context/AuthContext";
import { Button, makeStyles } from "@material-ui/core";
import ProfileContainer from "components/utils/ProfileContainer";
import ProfilePicUploader from "components/utils/ProfilePicUploader";
import CreateIcon from "@material-ui/icons/Create";
import { Link, useParams } from "react-router-dom";
import { db } from "config/Firebase";
import { CustomCard } from "components/utils/MaterialUICustomizedComponents";
import { Row } from "components/utils/MaterialUICustomizedComponents";
import { Column } from "components/utils/MaterialUICustomizedComponents";
import { CustomCardSkeleton } from "components/utils/MaterialUICustomizedComponents";
import { useHandling } from "context/HandleContext";
import { Skeleton } from "@material-ui/lab";
const useStyles = makeStyles((theme) => ({
  p: {
    color: theme.palette.primary.dark,
    textTransform: `capitalize`,
  },
}));

const Profile = () => {
  const { activeUser = {} } = useAuth();
  const [user, setUser] = useState({});
  const { setError } = useHandling();
  const [userLoaded, setUserLoaded] = useState(false);
  const [userMemes, setUserMemes] = useState([]);
  const [userMemesLoaded, setUserMemesLoaded] = useState(false);
  const { uid } = useParams();
  useEffect(() => {
    const fetchUser = async () => {
      try {
        if (uid === activeUser.uid) {
          setUser(activeUser);
        } else if (uid) {
          const user = (await db.collection("users").doc(uid).get()).data();
          if (user) setUser(user);
        } else if (activeUser.uid) {
          setUser(activeUser);
        } else {
          throw new Error("USER DOESN'T EXIST!!!!!!!!!!");
        }
        setUserLoaded(true);
      } catch (err) {
        setError(err);
      }
    };
    fetchUser();
  }, [uid, activeUser, setError]);
  const classes = useStyles();
  useEffect(() => {
    const fetchUserMemes = () => {
      return db
        .collection("memes")
        .where("user.uid", "==", user.uid)
        .orderBy("time", "desc")
        .onSnapshot((docs) => {
          const um = [];
          docs.forEach((doc) => um.push(doc.data()));
          setUserMemesLoaded(true);
          setUserMemes(um);
        });
    };
    const unsubMemes = user?.uid && fetchUserMemes();
    return () => unsubMemes?.();
  }, [user]);
  console.log(uid, activeUser.uid);
  return (
    <ProfileContainer user={user}>
      <div>
        <div className="row">
          <ProfilePicUploader user={user} />
          <div className="col-md-6">
            <div className="profile-head">
              {userLoaded ? (
                <h3>{user.username}</h3>
              ) : (
                <Skeleton width={100} height={40} />
              )}
            </div>
            <div className="w-100"></div>
            <div className="tab-content profile-tab" id="myTabContent">
              <div className="tab-pane d-block" id="home">
                {user.uid === activeUser.uid && user.email && (
                  <div className="row">
                    <div className="col-md-6">
                      <label className={classes.p}>Email</label>
                    </div>
                    <div className="col-md-6">
                      <p>{user.email}</p>
                    </div>
                  </div>
                )}
                {user.uid && activeUser.uid && user.uid === activeUser.uid && (
                  <Button
                    variant="contained"
                    component={Link}
                    to="/account/settings"
                    color="primary"
                    className="text-white"
                    startIcon={<CreateIcon color="inherit" />}
                  >
                    Edit Profile
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Row>
        {userMemesLoaded
          ? userMemes.map((el) => (
              <Column
                lg={!uid || uid === activeUser.uid ? 6 : 4}
                className="px-sm-2 px-1 py-sm-3 py-2 position-relative column"
              >
                <CustomCard {...el} />
              </Column>
            ))
          : [1, 2, 3].map(() => (
              <Column
                lg={!uid || uid === activeUser.uid ? 6 : 4}
                className="px-sm-2 px-1 py-sm-3 py-2 position-relative column"
              >
                <CustomCardSkeleton />
              </Column>
            ))}
      </Row>
    </ProfileContainer>
  );
};

export default Profile;
