import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import logo from "images/Memesbit_logo.png";
import { useAuth } from "context/AuthContext";
import { Avatar, Menu, MenuItem } from "@material-ui/core";
import { Link, NavLink } from "react-router-dom";
import { auth } from "config/Firebase";
import { useHandling } from "context/HandleContext";
import { usePopup } from "hooks/usePopup";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import SideDrawer from "./SideDrawer";
import useDeviceChecker from "hooks/useDeviceChecker";
import { FavoriteBorder, Publish } from "@material-ui/icons";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import SearchBar from "components/utils/SearchBar";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(1),
  },
  title: {
    flexGrow: 1,
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  simple: {
    "&:hover": {
      backgroundColor: `transparent`,
    },
  },
  colored: {
    color: theme.palette.secondary.main,
    "&:hover": {
      color: theme.palette.secondary.dark,
    },
  },
}));

export default function ButtonAppBar() {
  const { isAuth, user } = useAuth();
  const {
    login: [, setLoginOpen],
    upload: [, setUploadOpen],
    signup: [, setSignupOpen],
  } = usePopup();
  const checkMobile = useDeviceChecker();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const { setError, setSuccess } = useHandling();
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  return (
    <>
      <AppBar
        className="navbar navbar-expand-lg w-100 pb-0"
        color="default"
        position="sticky"
      >
        <Toolbar className="container" style={{ flexWrap: `nowrap` }}>
          {checkMobile && (
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              onClick={() => setOpen(true)}
            >
              <MenuIcon />
            </IconButton>
          )}
          <Typography
            component={Link}
            to="/"
            variant="h6"
            style={{ maxWidth: `70%` }}
            className="d-inline-block w-auto me-md-5"
          >
            <img
              width={200}
              style={{ maxWidth: `100%` }}
              src={logo}
              alt="Memesbit Logo"
            />
          </Typography>
          <div className="justify-content-end">
            <div className="navbar-nav">
              <ul className="navbar-nav d-flex justify-content-end flex-row mt-2 mt-lg-0 header-menu">
                {checkMobile ? (
                  <li className="nav-item p-2">
                    <IconButton
                      color="primary"
                      onClick={() => {
                        if (isAuth) {
                          setUploadOpen(true);
                        } else setError(new Error("Please Login !!!"));
                      }}
                    >
                      <Publish />
                    </IconButton>
                  </li>
                ) : (
                  <li className="nav-item p-2">
                    <Button
                      className="text-white"
                      variant="contained"
                      color="primary"
                      fullWidth={window.innerWidth < 768}
                      startIcon={<Publish />}
                      onClick={() => {
                        if (isAuth) {
                          setUploadOpen(true);
                        } else setError(new Error("Please Login !!!"));
                      }}
                    >
                      <>Upload Meme</>
                    </Button>
                  </li>
                )}
                {!checkMobile && (
                  <>
                    <li className="nav-item p-2">
                      <Button
                        color="primary"
                        startIcon={<MonetizationOnIcon />}
                        component="a"
                        className="text-white"
                        href="https://www.binance.cc/en/register?ref=DMEEV254"
                        target="_blank"
                        fullWidth={window.innerWidth < 768}
                        variant="contained"
                      >
                        Buy Crypto
                      </Button>
                    </li>
                    <li className="nav-item p-2">
                      <Button
                        color="primary"
                        startIcon={<FavoriteBorder />}
                        component={NavLink}
                        to="/donate"
                        fullWidth={window.innerWidth < 768}
                        variant="contained"
                        className="text-white"
                      >
                        Donate
                      </Button>
                    </li>
                  </>
                )}
                <li className="nav-item d-flex">
                  {!isAuth ? (
                    window.innerWidth > 768 && (
                      <>
                        <Button
                          onClick={() => setLoginOpen(true)}
                          startIcon={<AccountCircleIcon />}
                          color="primary"
                          variant="contained"
                          fullWidth={window.innerWidth < 768}
                          className="text-white m-2"
                        >
                          Login
                        </Button>
                        <Button
                          onClick={() => setSignupOpen(true)}
                          color="primary"
                          fullWidth={window.innerWidth < 768}
                          variant="contained"
                          className="text-white m-2"
                        >
                          Signup
                        </Button>
                      </>
                    )
                  ) : (
                    <>
                      {!checkMobile ? (
                        <Button
                          className={`${classes.simple} m-2`}
                          onClick={handleClick}
                          variant="outlined"
                          startIcon={
                            <Avatar
                              className={classes.small}
                              alt={`@${user.displayName}`}
                              src={user?.photoURL}
                            >
                              {user.displayName?.[0]}
                            </Avatar>
                          }
                        >
                          <b>{user.displayName}</b>
                        </Button>
                      ) : (
                        <IconButton onClick={handleClick}>
                          <Avatar
                            className={classes.small}
                            alt={`@${user.displayName}`}
                            src={user?.photoURL}
                          >
                            {user.displayName?.[0]}
                          </Avatar>
                        </IconButton>
                      )}
                      <Menu
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                        style={{ minWidth: 200, top: 40 }}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "center",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "center",
                        }}
                        MenuListProps={{ style: { minWidth: 150 } }}
                      >
                        <MenuItem
                          component={Link}
                          to={`/user/${user.uid}`}
                          onClick={handleClose}
                        >
                          Profile
                        </MenuItem>
                        <MenuItem
                          onClick={async () => {
                            try {
                              await auth.signOut();
                              handleClose();
                              setSuccess(new Error("Successfully logged out"));
                            } catch (err) {
                              handleClose();
                              setError(err);
                            }
                          }}
                        >
                          Logout
                        </MenuItem>
                      </Menu>
                    </>
                  )}
                </li>
              </ul>
            </div>
          </div>
        </Toolbar>
      </AppBar>
      {checkMobile && <SideDrawer {...{ open, setOpen, anchor: "left" }} />}
      <div className="d-flex justify-content-center align-items-center">
        <SearchBar />
      </div>
    </>
  );
}
