import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
// import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
// import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import logo from "images/Memesbit_logo.png";
import { Link } from "react-router-dom";
import {
  AccountCircle,
  FavoriteBorder,
  MonetizationOn,
} from "@material-ui/icons";
import { ListItemIcon } from "@material-ui/core";
import { usePopup } from "hooks/usePopup";
import { useAuth } from "context/AuthContext";

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
});

export default function TemporaryDrawer({ open = false, setOpen, anchor }) {
  const classes = useStyles();
  const { isAuth } = useAuth();
  const {
    login: [, setLoginOpen],
    signup: [, setSignupOpen],
  } = usePopup();
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpen(open);
  };

  const list = () => (
    <div
      className={classes.list}
      role="presentation"
      onKeyDown={toggleDrawer(false)}
    >
      <div className="w-100">
        <img
          width={200}
          style={{ maxWidth: `100%` }}
          src={logo}
          className="py-3 px-3"
          alt="Memesbit Logo"
        />
      </div>
      <List>
        <ListItem component={Link} to="/donate" button>
          <ListItemIcon>
            <FavoriteBorder />
          </ListItemIcon>
          Donate
        </ListItem>
        <ListItem
          component="a"
          className="text-dark"
          href="https://www.binance.cc/en/register?ref=DMEEV254"
          target="_blank"
          button
        >
          <ListItemIcon>
            <MonetizationOn />
          </ListItemIcon>
          Buy Crypto
        </ListItem>
        {!isAuth && (
          <>
            <ListItem
              onClick={() => {
                setLoginOpen(true);
              }}
              button
            >
              <ListItemIcon>
                <AccountCircle />
              </ListItemIcon>
              Login
            </ListItem>
            <ListItem
              onClick={() => {
                setSignupOpen(true);
              }}
              button
            >
              <ListItemIcon>
                <AccountCircle />
              </ListItemIcon>
              Signup
            </ListItem>
          </>
        )}
      </List>
    </div>
  );
  return (
    <div>
      <React.Fragment key={anchor}>
        <Drawer anchor={anchor} open={open} onClose={toggleDrawer(false)}>
          {list(anchor)}
        </Drawer>
      </React.Fragment>
    </div>
  );
}
