import React, { useRef } from "react";
import Input from "../utils/Input";
import { Paper } from "@material-ui/core";
import { useAuth } from "context/AuthContext";
import { useHandling } from "context/HandleContext";
import { CustomButton } from "../utils/MaterialUICustomizedComponents";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import Logo from "images/Memesbit_logo.png";
import { usePopup } from "hooks/usePopup";
const ForgotPopup = () => {
  const { setError, setSuccess } = useHandling();
  const { reset } = useAuth();
  const email = useRef();
  const {
    forgot: [open, setOpen],
    login: [, setLoginOpen],
    signup: [, setSignupOpen],
  } = usePopup();
  const onSubmit = async (e) => {
    try {
      e.preventDefault();
      await reset(email.current.value);
      setSuccess(
        new Error(
          `Password Reset Email Has Been Successfully to: ${email.current.value} Sent Check Your Inbox for further details`
        )
      );
    } catch (err) {
      setError(err);
    }
  };
  return (
    <div>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="form-dialog-title"
      >
        <Paper className="login_inner text-center">
          <img src={Logo} width={250} alt="Memesbit" />
          <form onSubmit={onSubmit} action="/" className="login_form">
            <h2>Find your account</h2>
            <div className="row w-100">
              <div className="col-12">
                <Input
                  required
                  className="w-100"
                  placeholder="Email"
                  name="email"
                  ref={email}
                  id="email"
                  type="email"
                  fullWidth
                />
              </div>
            </div>
            <button className="button_login mx-auto">Find Now</button>
          </form>
          <DialogActions className="d-flex justify-content-evenly">
            <CustomButton
              onClick={() => {
                setOpen(false);
                setLoginOpen(true);
              }}
              color="primary"
            >
              Login Now
            </CustomButton>
            <CustomButton
              onClick={() => {
                setOpen(false);
                setSignupOpen(true);
              }}
              color="primary"
            >
              SignUp Now
            </CustomButton>
          </DialogActions>
        </Paper>
      </Dialog>
    </div>
  );
};

export default ForgotPopup;
