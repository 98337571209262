import firebase, {
  db,
  facebookProvider,
  googleProvider,
  twitterProvider,
} from "config/Firebase";
import React, { useState, useEffect, createContext, useContext } from "react";
export const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState({});
  const [isAuth, setIsAuth] = useState(false);
  const [presistant, setPresistant] = useState(false);
  const [activeUser, setactiveUser] = useState({});
  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
        db.collection("users")
          .doc(user.uid)
          .onSnapshot((snap) => {
            const user = { ...snap.data() };
            user.id = snap.id;
            if (snap.exists) {
              setIsAuth(true);
            } else {
              setIsAuth(false);
            }
            setactiveUser(user);
            setPresistant(true);
          });
      } else {
        setUser({});
        setPresistant(true);
        setactiveUser({});
        setIsAuth(false);
      }
    });
  }, []);
  const login = (email, password) =>
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((res) => {
        setUser(res.user);
        setIsAuth(true);
        return res;
      });
  const currentUser = () => firebase.auth().currentUser;
  const signup = async (email, password, username, resolve, reject) => {
    try {
      const { lat, lon, ...location } = await (
        await fetch("https://extreme-ip-lookup.com/json/")
      ).json();
      const cred = await firebase
        .auth()
        .createUserWithEmailAndPassword(email, password);
      setUser(cred.user);
      setIsAuth(true);
      const coordinates = new firebase.firestore.GeoPoint(
        parseInt(lat),
        parseInt(lon)
      );
      location.coordinates = coordinates;
      const user = {
        email,
        location,
        time: firebase.firestore.FieldValue.serverTimestamp(),
        type: "manual",
        lowercase_username: username.toLowerCase(),
        username,
        uid: cred.user.uid,
        role: "user",
        id: cred.user.uid,
        likes: 0,
      };
      setactiveUser(user);
      await cred.user.updateProfile({ displayName: username });
      await db.collection("users").doc(cred.user.uid).set(user);
      resolve(cred);
    } catch (err) {
      reject(err);
    }
  };
  const logout = (resolve) => {
    firebase.auth().signOut().then(resolve);
  };
  const googleLogin = async (res, rej) => {
    try {
      const result = await firebase.auth().signInWithPopup(googleProvider);
      setUser(result.user);
      setIsAuth(true);
      const value = {
        email: result.user.email,
        type: "google",
        dp: result.user.photoURL,
        time: Date.now(),
        lowercase_username: result.user.displayName.toLowerCase(),
        username: result.user.displayName,
        uid: result.user.uid,
        role: "user",
        likes: 0,
      };
      if (!result.additionalUserInfo.isNewUser) return res(result);
      await db.collection("users").doc(result.user.uid).set(value);
      res(result);
    } catch (err) {
      rej(err);
    }
  };
  const facebookLogin = async (res, rej) => {
    try {
      const result = await firebase.auth().signInWithPopup(facebookProvider);
      setUser(result.user);
      setIsAuth(true);
      const value = {
        email: result.user.email,
        type: "facebook",
        dp: result.user.photoURL,
        time: Date.now(),
        lowercase_username: result.user.displayName.toLowerCase(),
        username: result.user.displayName,
        uid: result.user.uid,
        role: "user",
        likes: 0,
      };
      if (!result.additionalUserInfo.isNewUser) return res(result);
      await db.collection("users").doc(result.user.uid).set(value);
      res(result);
    } catch (err) {
      rej(err);
    }
  };
  const twitterLogin = async (res, rej) => {
    try {
      const result = await firebase.auth().signInWithPopup(twitterProvider);
      setUser(result.user);
      setIsAuth(true);
      const value = {
        email: result.user.email,
        type: "twitter",
        dp: result.user.photoURL,
        time: Date.now(),
        lowercase_username: result.user.displayName.toLowerCase(),
        username: result.user.displayName,
        uid: result.user.uid,
        role: "user",
        likes: 0,
      };
      if (!result.additionalUserInfo.isNewUser) return res(result);
      await db.collection("users").doc(result.user.uid).set(value);
      res(result);
    } catch (err) {
      rej(err);
    }
  };
  const reset = (email) => firebase.auth().sendPasswordResetEmail(email);
  const verify = (res, rej) => {
    firebase
      .auth()
      .currentUser.sendEmailVerification()
      .then((e) => res(e))
      .catch((e) => rej(e));
  };
  const value = {
    user,
    reset,
    facebookLogin,
    verify,
    isAuth,
    setIsAuth,
    login,
    signup,
    currentUser,
    activeUser,
    logout,
    googleLogin,
    twitterLogin,
    presistant,
  };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthContextProvider;
