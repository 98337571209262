import React from "react";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import InstagramIcon from "@material-ui/icons/Instagram";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import { makeStyles } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  footer: {
    textAlign: `center`,
    backgroundColor: theme.palette.background.paper,
  },
}));
const Footer = () => {
  const classes = useStyles();
  return (
    <footer className={classes.footer}>
      {/* Grid container */}
      <div className="container p-4">
        {/* Section: Social media */}
        <section className="mb-4">
          {/* Facebook */}
          <a
            className="btn btn-primary p-2 rounded-pill border-0 btn-floating m-1"
            style={{ backgroundColor: "#3b5998" }}
            href="#!"
            role="button"
          >
            <FacebookIcon />
          </a>
          {/* Twitter */}
          <a
            className="btn btn-primary p-2 rounded-pill border-0 btn-floating m-1"
            style={{ backgroundColor: "#55acee" }}
            href="#!"
            role="button"
          >
            <TwitterIcon />
          </a>
          {/* Instagram */}
          <a
            className="btn btn-primary p-2 rounded-pill border-0 btn-floating m-1"
            style={{ backgroundColor: "#ac2bac" }}
            href="#!"
            role="button"
          >
            <InstagramIcon />
          </a>
          {/* Linkedin */}
          <a
            className="btn btn-primary p-2 rounded-pill border-0 btn-floating m-1"
            style={{ backgroundColor: "#0082ca" }}
            href="#!"
            role="button"
          >
            <LinkedInIcon />
          </a>
        </section>
      </div>
      <div
        className="text-center p-3"
        style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}
      >
        © 2020 Copyright: Memesbit. All rights reserved
      </div>
      {/* Copyright */}
    </footer>
  );
};

export default Footer;
