import { Button, Paper } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { auth, db, storage } from "config/Firebase";
import { useAuth } from "context/AuthContext";
import { useHandling } from "context/HandleContext";
import Loader from "./Loader";

const ProfilePicUploader = ({ editable = false, user: activeUser = {} }) => {
  const { setError, setSuccess, loading, setLoading } = useHandling();
  const [img, setImg] = useState(null);
  const [fileSelected, setfileSelected] = useState(null);
  const { user } = useAuth();
  const [blob, setblob] = useState(null);
  useEffect(() => {
    setImg(activeUser?.uid === user.uid ? user.photoURL : activeUser?.dp);
  }, [user.photoURL, user.uid, activeUser.uid, activeUser.dp]);
  const crop = (url, aspectRatio = 1) => {
    return new Promise((resolve) => {
      // this image will hold our source image data
      const inputImage = new Image();
      inputImage.src = url;
      // we want to wait for our image to load
      inputImage.onload = () => {
        // let's store the width and height of our image
        const inputWidth = inputImage.naturalWidth;
        const inputHeight = inputImage.naturalHeight;

        // get the aspect ratio of the input image
        const inputImageAspectRatio = inputWidth / inputHeight;

        // if it's bigger than our target aspect ratio
        let outputWidth = inputWidth;
        let outputHeight = inputHeight;
        if (inputImageAspectRatio > aspectRatio) {
          outputWidth = inputHeight * aspectRatio;
        } else if (inputImageAspectRatio < aspectRatio) {
          outputHeight = inputWidth / aspectRatio;
        }

        // calculate the position to draw the image at
        const outputX = (outputWidth - inputWidth) * 0.5;
        const outputY = (outputHeight - inputHeight) * 0.5;

        // create a canvas that will present the output image
        const outputImage = document.createElement("canvas");
        // set it to the same size as the image
        outputImage.width = outputWidth;
        outputImage.height = outputHeight;
        // draw our image at position 0, 0 on the canvas
        const ctx = outputImage.getContext("2d");
        ctx.drawImage(inputImage, outputX, outputY);
        outputImage.toBlob((blob) => setblob(blob));
        resolve(outputImage);
      };
    });
  };
  const handleFileChange = (e) => {
    const file = e.target?.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      setTimeout(
        () =>
          crop(reader.result).then((img) => {
            setfileSelected(true);
            setImg(img.toDataURL());
          }),
        1000
      );
    }
  };
  return (
    <div className="col-md-4 text-center">
      {loading && <Loader />}
      <div className="profile-img mb-3">
        {activeUser.dp ? (
          <Paper
            elevation={3}
            style={{ width: 200, height: 200 }}
            className="rounded-circle mx-auto overflow-hidden"
          >
            <img src={img} className="w-100" alt={`@${activeUser.username}`} />
          </Paper>
        ) : (
          <Paper
            elevation={3}
            className="bg-light rounded-circle d-flex justify-content-center align-items-center display-2 border"
            style={{ width: `200px`, height: `200px` }}
          >
            {" "}
            {activeUser.username?.[0]}{" "}
          </Paper>
        )}
      </div>
      {fileSelected ? (
        <div className="d-flex" style={{ justifyContent: `space-evenly` }}>
          <Button
            variant="contained"
            onClick={async () => {
              setLoading(true);
              try {
                await storage.ref().child(`/users/${activeUser.uid}`).put(blob);
                const dp = await storage
                  .ref()
                  .child(`/users/${activeUser.uid}`)
                  .getDownloadURL();
                auth.currentUser.updateProfile({ photoURL: dp });
                await db
                  .collection("users")
                  .doc(activeUser.uid)
                  .set({ dp }, { merge: true });
                setSuccess(
                  new Error("User Image has been Updated Successfully")
                );
                setLoading(false);
              } catch (error) {
                setError(error);
                setLoading(false);
              }
            }}
            color="primary"
          >
            Ok
          </Button>
        </div>
      ) : (
        <>
          {editable && (
            <>
              <input type="file" onChange={handleFileChange} hidden id="file" />
              <Button
                component="label"
                htmlFor="file"
                variant="contained"
                color="primary"
              >
                Upload File
              </Button>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default ProfilePicUploader;
