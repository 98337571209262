import React from "react";
import CheckIcon from "@material-ui/icons/Check";
import { Button, Paper } from "@material-ui/core";
import { useAuth } from "context/AuthContext";
import { useHandling } from "context/HandleContext";
import { auth } from "config/Firebase";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import { CustomButton } from "../utils/MaterialUICustomizedComponents";
import { usePopup } from "hooks/usePopup";
const Verification = () => {
  const { verify, user } = useAuth();
  // const [loading, setloading] = useState(false);
  const { setSuccess, setError } = useHandling();
  const {
    login: [, setOpen],
  } = usePopup();
  const onClick = () => {
    setError(false);
    // setloading(true);
    setSuccess(false);
    verify(
      () => {
        // setloading(false);
        setSuccess({
          message: `A verification mail has been sent to your email: ${user.email}. Verify User and Login Again`,
        });
        auth.signOut();
        setOpen(true);
      },
      (err) => {
        // setloading(false);
        setError(err);
      }
    );
  };
  return (
    <div className="verification py-5 my-5">
      <section className="content border container-fluid rounded">
        <div className="row">
          <Paper className="col-12 py-3">
            <h1 className="text-lg">Memesbit</h1>
            <div className="text-md text-color">Join Us Now!</div>
            <div>
              <Button
                onClick={onClick}
                variant="contained"
                color="primary"
                endIcon={<CheckIcon />}
              >
                Verify Account
              </Button>
            </div>
            <p className="regular-text">
              Not {user.displayName} Logged in Here?
            </p>
            <CustomButton
              onClick={() =>
                auth
                  .signOut()
                  .then(() => setSuccess(new Error("Successfully Logged Out")))
                  .catch((err) => setError(err))
              }
              color="primary"
              endIcon={<PowerSettingsNewIcon />}
            >
              Logout
            </CustomButton>
          </Paper>
        </div>
      </section>
    </div>
  );
};

export default Verification;
