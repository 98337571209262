import { useCallback } from "react";
import Quill from "quill";
import "quill/dist/quill.snow.css";

const toolbar = [];

const PostField = ({ setQuill }) => {
  const wrapperRef = useCallback(
    (wrapper) => {
      if (wrapper == null) return;
      wrapper.innerHTML = "";
      const editor = document.createElement("div");
      editor.style.height = `150px`;
      editor.style.maxHeight = `150px`;
      editor.style.overflow = `auto`;
      editor.style.borderBottom = `0`;
      wrapper.append(editor);
      const quill = new Quill(editor, {
        theme: "snow",
        modules: { toolbar },
        placeholder: `Add Tags`,
      });
      setQuill(quill);
    },
    [setQuill]
  );
  return (
    <>
      <div ref={wrapperRef}></div>
      <div className="px-2">Separate with commas to add multiple tags.</div>
    </>
  );
};

export default PostField;
