import { Typography } from "@material-ui/core";
import Collapseable from "components/utils/Collapseable";
import React from "react";

const terms = [
  {
    name: "Your Account.",
    children: (
      <>
        You may need to sign up for an account on Memesbit in order to use parts
        of the Services. You must provide accurate and up to date information
        for your account. You promise not to
        <ol>
          <li>
            intentionally impersonate another person by using their name or
            email address
          </li>
          <li>use an offensive name or email address,</li>
          <li>
            use a name or email address for which you do not have proper
            authorization.
          </li>
        </ol>
        We reserve the right to require that you change your username or use
        another email address. You are prohibited from using another person’s
        account or registration information for the Services without their
        permission. You are responsible for all activity that occurs on your
        account, and for keeping your password secure. You promise to
        immediately let us know if there is any unauthorized use of your
        account. You can delete your account at any time, either directly or
        through a request to us.
      </>
    ),
  },
  {
    name: "Content You Contribute.",
    children:
      "You are solely responsible for all of content you contribute to the Services (“User Content”) and as between you and Memesbit, you own your User Content. You hereby do and shall grant us a non-exclusive, worldwide, perpetual, royalty-free, fully paid, transferable, sublicensable right to use, modify, reproduce, distribute, prepare derivative works of, display, perform and otherwise fully exploit such User Content (including all related intellectual property rights) in connection with the Services and our business. You promise that you have all rights to grant such license to us without infringement or violation of any third party rights. If you contribute User Content through a public part of the Services, you acknowledge that such User Content will be accessible to other users. Please do not publicly post or submit any User Content that you do not want publicly accessible or viewable, or that you do not have rights to post.",
  },
  {
    name: "Proprietary Rights.",
    children:
      "We and our licensors own and retain all proprietary rights in the Services. Subject to these Terms, we grant you a limited, non-exclusive, non-transferable, and revocable license to use the Services. The Services may contain copyrighted material (such as text, graphics, photographs, images, and illustrations), trademarks, and other proprietary information and materials of us and our licensors. Except for content that is in the public domain or content that you have permission to use in connection with your use of the Services and in compliance with these Terms of Service, you shall not copy, modify, publish, transmit, distribute, perform, or display any content, nor shall you sell, license, rent, or otherwise use or exploit any content for commercial use or in any way that violates any third party right. The Services are protected by international copyright laws. We can (but do not have to) remove, block, edit or modify any content in our sole discretion at any time, without notice to you and for any reason or for no reason at all. We reserve the right to access, read, preserve, and disclose any information as we reasonably believe is necessary to satisfy applicable laws or protect the rights, property or safety of us, our users and the public.",
  },
  {
    name: "Links to Third Party Services.",
    children:
      "You may be able to link to third party websites, services or resources (collectively, “Third Party Services”) on the internet, and some Third Party Services may link to the Services. We do not control Third Party Services in any way, and you acknowledge and agree that we are not responsible or liable, directly or indirectly, for the content, functions, legality or any other aspect or materials of any Third Party Services, or for any damage or loss in connection with your use of any Third Party Services. We encourage you to review the terms of service and privacy policy of any such Third Party Services.",
  },
  {
    name: "Termination.",
    children:
      "We may terminate or suspend your account or access to the Services at any time, with or without notice, which may result in the loss of all information associated with your account. You may also delete your account and/or your use of the Services at any time by following the directions through the Services. All provisions of these Terms which by their nature should survive termination shall survive, including without limitation, ownership provisions, warranty disclaimers, indemnity, limitation of liability, and dispute procedures. ",
  },
  {
    name: "Warranty Disclaimer.",
    children: (
      <>
        You acknowledge that we have no control over, and no duty to take any
        action regarding:
        <ol>
          <li>what content you access via the Services;</li>
          <li>
            what effects the content may have on you, and how you may interpret
            or use the content;
          </li>
          <li>
            what actions you may take as a result of having been exposed to the
            content.
          </li>
        </ol>
        We make no representations concerning any content contained in or
        accessed through the Services, and we will not be responsible or liable
        for the accuracy, copyright compliance, legality or decency of material
        contained in or accessed through the Services. THE SERVICES AND CONTENT
        ARE PROVIDED ON AN "AS IS" BASIS, WITHOUT WARRANTIES OF ANY KIND, EITHER
        EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, IMPLIED WARRANTIES OF
        MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, OR
        THAT USE OF THE SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE. YOUR USE
        OF THE SERVICES IS SOLELY AT YOUR OWN RISK.
      </>
    ),
  },
  {
    name: "Miscellaneous.",
    children:
      "These Terms contain the entire agreement between you and us regarding the use of the Services. If any provision of these Terms is held to be invalid, illegal or unenforceable in any respect, that provision shall be limited or eliminated to the minimum extent necessary so that these Terms shall otherwise remain in full force and effect and enforceable. Our failure to enforce any part of these Terms shall not constitute a waiver of our right to later enforce that or any other part of these Terms. In order for any waiver of compliance with these Terms to be binding, we must provide you with written notice of such waiver. You and we are independent contractors, and no agency, partnership, or joint venture relationship is intended or created by these Terms. The section and paragraph headings in these Terms are for convenience only and shall not affect the interpretation of these Terms. You agree that, except as otherwise expressly provided in these Terms there shall be no third party beneficiaries. We may assign, transfer or delegate any of our rights and obligations hereunder without consent.",
  },
];

const Terms = () => {
  return (
    <div>
      <div className="container">
        <Typography variant="h1">Terms of service</Typography>
        <Typography variant="body1">
          Please read these Terms fully and carefully before using the Services,
          because these Terms form a legally binding contract between you and
          Memesbit for your use of the Services. By using the Services, you
          agree to be bound by these Terms. From time to time, we may modify or
          update these Terms, effective upon posting through the Services. If
          you use the Services after any such change, you accept these Terms as
          modified.
        </Typography>
        <div className="py-4">
          {terms.map((el, i) => (
            <Collapseable i={i} {...el} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Terms;
