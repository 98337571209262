import { Typography } from "@material-ui/core";
import Loader from "components/utils/Loader";
import { Column } from "components/utils/MaterialUICustomizedComponents";
import { CustomUserCard } from "components/utils/MaterialUICustomizedComponents";
import { Row } from "components/utils/MaterialUICustomizedComponents";
import { CustomCard } from "components/utils/MaterialUICustomizedComponents";
import { db } from "config/Firebase";
import { useHandling } from "context/HandleContext";
import React, { useEffect, useState } from "react";

const Search = () => {
  const { setError } = useHandling();
  const [result, setResult] = useState([]);
  const [resultUsers, setResultUsers] = useState([]);
  const [loading, setLoading] = useState([false, false]);
  const value = decodeURIComponent(
    new URLSearchParams(window.location.search).get("tags")
  );
  useEffect(() => {
    const value = decodeURIComponent(
      new URLSearchParams(window.location.search).get("tags")
    );
    setLoading([true, true]);
    const manipulatedSearch = value.split(",").map((str) => {
      const df = str.trim().replace(/\s/g, "_");
      return str.includes("#") ? df : "#" + df;
    });
    const unsub = db
      .collection("memes")
      .where("tags", "array-contains-any", manipulatedSearch)
      .orderBy("time", "desc")
      .onSnapshot(
        (docs) => {
          const d = docs.docs.map((doc) => doc.data());
          setResult(d);
          setLoading((e) => [false, e[1]]);
        },
        (err) => {
          console.error(err);
          setError(err);
          setLoading([false, false]);
        }
      );
    const usub2 = db
      .collection("users")
      .orderBy("lowercase_username")
      .startAt(value.toLowerCase())
      .endAt(value.toLowerCase() + "\uf8ff")
      .onSnapshot(
        (docs) => {
          const d = docs.docs.map((doc) => doc.data());
          setResultUsers(d);
          setLoading((e) => [e[0], false]);
        },
        (err) => {
          console.error(err);
          setError(err);
          setLoading([false, false]);
        }
      );
    return () => {
      unsub();
      usub2();
    };
  }, [setError]);
  return (
    <div>
      <div className="container">
        <Typography variant="h1">Results</Typography>
        <Typography variant="h5">
          {result.length + resultUsers.length} Search results for: "{value}"
        </Typography>
        <Typography variant="h2">Memes</Typography>
        {result.length ? (
          <Row className="mx-0 pb-4">
            {result?.map?.((el) => (
              <Column
                lg={4}
                className="px-sm-2 px-1 py-sm-3 py-2 position-relative"
              >
                <CustomCard {...el} />
              </Column>
            ))}
          </Row>
        ) : (
          <Typography variant="h6">No Memes Found</Typography>
        )}
        <Typography variant="h2">Users</Typography>
        {resultUsers.length ? (
          <Row className="mx-0">
            {resultUsers?.map?.((el) => (
              <Column
                lg={4}
                className="px-sm-2 px-1 py-sm-3 py-2 position-relative"
              >
                <CustomUserCard {...el} />
              </Column>
            ))}
          </Row>
        ) : (
          <Typography variant="h6">No Users Found</Typography>
        )}
        {!loading.includes(false) && <Loader fullPage />}
      </div>
    </div>
  );
};

export default Search;
