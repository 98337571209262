import firebase from "firebase/app";

// Add the Firebase products that you want to use
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
const firebaseConfig = {
  apiKey: "AIzaSyDmcke0YORMVQ3lfPHp6MN1OP7pK3uEEF0",
  authDomain: "membit-fbf23.firebaseapp.com",
  projectId: "membit-fbf23",
  storageBucket: "membit-fbf23.appspot.com",
  messagingSenderId: "997971207583",
  appId: "1:997971207583:web:c53dda1d47e9922f3e09aa",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const googleProvider = new firebase.auth.GoogleAuthProvider();
const facebookProvider = new firebase.auth.FacebookAuthProvider();
const twitterProvider = new firebase.auth.TwitterAuthProvider();

// DATABASE
var db = firebase.firestore();
var storage = firebase.storage();
var auth = firebase.auth();

export { db, storage, auth, googleProvider, facebookProvider, twitterProvider };
export default firebase;
