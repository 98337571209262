import {
  Button,
  withStyles,
  makeStyles,
  Box,
  Avatar,
  Typography,
  Card,
  CardMedia,
  CardActionArea,
  CardContent,
  Paper,
  ListItem,
  ListItemAvatar,
} from "@material-ui/core";
import Color from "color";
import firebase, { db } from "config/Firebase";
import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  ThumbUpAltOutlined,
  ThumbUpAlt,
  Share,
  Delete,
  Visibility,
} from "@material-ui/icons";
import { useAuth } from "context/AuthContext";
import ShareDropdown from "./ShareDropdown";
import { useHandling } from "context/HandleContext";
import useDeviceChecker from "hooks/useDeviceChecker";
import { Link, useHistory } from "react-router-dom";
import { Skeleton } from "@material-ui/lab";
export const Row = ({ children, className, ...props }) => (
  <div {...props} className={`row ${className}`}>
    {" "}
    {children}{" "}
  </div>
);
export const Column = ({
  children,
  lg,
  md,
  sm,
  xs,
  className,
  component,
  ...props
}) => {
  const Component = component || "div";
  const check = (t, s) => {
    return t ? `${s}${t} ` : "";
  };
  return (
    <Component
      {...props}
      className={`${check(lg, `col-lg-`)}${check(md, `col-md-`)}${check(
        sm,
        `col-sm-`
      )}${check(xs, `col-`)}${className || ""}`}
    >
      {children}
    </Component>
  );
};
const useStyles = makeStyles((theme) => ({
  card: {
    position: "relative",
    boxShadow: "0 8px 24px 0 rgba(0,0,0,0.12)",
    overflow: "hidden",
    borderRadius: "1.5rem",
    transition: "0.4s",
  },
  color: {
    color: theme.palette.text.primary,
  },
  main: {
    overflow: "hidden",
    borderTopLeftRadius: "1.5rem",
    borderTopRightRadius: "1.5rem",
    zIndex: 1,
    color: theme.palette.text.primary,
    paddingBottom: `100%`,
    backgroundColor: `#242424`,
  },
  content: {
    position: "absolute",
    bottom: 0,
    width: "100%",
    zIndex: 1,
    padding: "1.5rem 1.5rem 1rem",
  },
  avatar: {
    width: 48,
    height: 48,
  },
  tag: {
    display: "inline-block",
    backgroundColor: "#5432fa",
    borderRadius: "5rem",
    padding: "2px 0.5rem",
    color: "#fff",
    fontSize: `0.75rem`,
    margin: "0.5rem 0.25rem",
    "&:first-child": {
      marginLeft: `0 !important`,
    },
    "&:last-child": {
      marginRight: 0,
    },
  },
  title: {
    fontSize: "1rem",
  },
  author: {
    zIndex: 1,
    position: "relative",
  },
  image: {
    maxWidth: "100%",
    maxHeight: "100%",
    objectFit: "cover",
    zIndex: "0",
    position: `absolute`,
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%,-50%)`,
  },
}));
const useUserCardStyles = makeStyles((theme) => ({
  actionArea: {
    borderRadius: 16,
    transition: "0.2s",
    "&:hover": {
      transform: "scale(1.05)",
    },
  },
  card: {
    borderRadius: 16,
    boxShadow: "none",
    "&:hover": {
      boxShadow: `0 6px 12px 0 ${Color(theme.palette.primary.main)
        .rotate(-12)
        .darken(0.2)
        .fade(0.5)}`,
    },
  },
  content: {
    backgroundImage: `linear-gradient(to right bottom,${theme.palette.primary.main},${theme.palette.primary.dark})`,
    padding: "1rem 1rem 1rem",
    paddingBottom: "1rem !important",
  },
  title: {
    color: "#fff",
    textTransform: "lowercase",
    fontSize: `1.2rem`,
    marginBottom: `0.5rem`,
  },
  subtitle: {
    color: "#fff",
    opacity: 0.87,
    marginTop: `0.5rem`,
    fontWeight: 500,
    fontSize: `0.9rem`,
  },
  image: {
    aspectRatio: `1/1`,
  },
}));
export const CustomButton = withStyles((theme) => {
  const color = (props, mode) =>
    props.variant !== "contained" &&
    (props.customColor || theme.palette[props.color]?.[mode] || "");
  const backgroundColor = (props, mode) =>
    props.variant === "contained" &&
    (props.customColor || theme.palette[props.color]?.[mode] || "");
  return {
    root: {
      color: (props) => color(props, "main"),
      backgroundColor: (props) => backgroundColor(props, "main"),
      "&:hover": {
        color: (props) => color(props, "dark"),
        backgroundColor: (props) => backgroundColor(props, "dark"),
      },
    },
  };
})(Button);

export const CustomCard = ({
  user: { uid },
  image = "",
  time,
  tags = [],
  likes = [],
  id,
  views = 0,
}) => {
  const styles = useStyles();
  const { activeUser, isAuth } = useAuth();
  const { setError, setSuccess } = useHandling();
  const { push } = useHistory();
  const [user, setUser] = useState({});
  const [open, setOpen] = useState(null);
  const [url, setUrl] = useState("");
  const check = useDeviceChecker();
  useEffect(() => {
    if (id) setUrl(`${window.location.toString()}memes/${id}`);
  }, [id]);
  const [LikeIcon, setIcon] = useState(ThumbUpAltOutlined);
  useEffect(() => {
    const fetchUser = async () => {
      const user = await db.collection("users").doc(uid).get();
      setUser(user.data());
    };
    fetchUser();
  }, [uid, user.uid]);
  useEffect(() => {
    if (likes.includes(activeUser.uid)) setIcon(ThumbUpAlt);
    else setIcon(ThumbUpAltOutlined);
  }, [activeUser.uid, likes]);

  const likeUnlikeMeme = async () => {
    try {
      if (isAuth) {
        const method = likes.includes(activeUser.uid)
          ? "arrayRemove"
          : "arrayUnion";
        const userRef = db.collection("users").doc(uid);
        const batch = db.batch();
        batch.set(
          db.collection("memes").doc(id),
          {
            likes: firebase.firestore.FieldValue[method](activeUser.uid),
          },
          { merge: true }
        );
        batch.set(
          userRef,
          {
            likes: firebase.firestore.FieldValue.increment(
              likes.includes(activeUser.uid) ? -1 : 1
            ),
          },
          { merge: true }
        );
        await batch.commit();
      } else {
        throw new Error("Please Login to Like the item.");
      }
    } catch (err) {
      setError(err);
    }
  };
  function m(n, a) {
    let x = ("" + n).length,
      p = Math.pow,
      d = p(10, a);
    x -= x % 3;
    return Math.round((n * d) / p(10, x)) / d + " kMGTPE"[x / 3];
  }
  return (
    <>
      <Card component={Paper} className={styles.card}>
        <CardActionArea
          component={Link}
          className="d-block text-dark text-decoration-none"
          onClick={async () => {
            try {
              if (!(activeUser.uid === uid)) {
                await db
                  .collection("memes")
                  .doc(id)
                  .update({
                    views: firebase.firestore.FieldValue.increment(1),
                  });
              }
            } catch (err) {
              // setError(err);
            }
          }}
          to={`/memes/${id}`}
        >
          <Box className={styles.main} position={"relative"}>
            <CardMedia component="img" className={styles.image} src={image} />
          </Box>
          <div className="container-fluid px-3">
            <div className="d-flex">
              <div className="d-flex flex-wrap">
                <span className="my-2">&nbsp;</span>
                {tags?.slice(0, 2).map((el) => (
                  <div className={styles.tag}>{el}</div>
                ))}
                {tags?.length > 2 && <div className="ml-2 h3">...</div>}
              </div>
            </div>
          </div>
          <div className={`container-fluid px-3 pb-2`}>
            <Row className="mx-0">
              <div className="p-1 w-auto">
                <Avatar
                  className={`shadow ${styles.avatar}`}
                  src={user.dp}
                  title={`@${user?.username?.split(" ").join("_")}`}
                  alt={`@${user?.username?.split(" ").join("_")}`}
                />
              </div>
              <div className="align-self-center w-auto p-1">
                <Typography className="fw-bold">{user?.username}</Typography>
                <Typography style={{ fontSize: `0.9rem` }}>
                  {moment(time?.toDate()).format("MMM Do")} |{" "}
                  {moment(time?.toDate()).fromNow()}
                </Typography>
                <div></div>
              </div>
            </Row>
          </div>
        </CardActionArea>
        <Row className="mx-0">
          {!(activeUser.uid === uid) && (
            <Column className="px-0" xs={4}>
              <Button
                onClick={likeUnlikeMeme}
                color={likes.includes(activeUser.uid) ? "primary" : "default"}
                startIcon={<LikeIcon style={{ fontSize: `1rem` }} />}
                fullWidth
                style={{ fontSize: `0.75rem` }}
                className="py-3"
              >
                {m(likes.length, 1)} Like{likes.length > 1 ? "s" : ""}
              </Button>
            </Column>
          )}
          <Column className="px-0" xs={4}>
            <Button
              startIcon={<Visibility style={{ fontSize: `1rem` }} />}
              style={{ fontSize: `0.75rem` }}
              fullWidth
              className="py-3"
            >
              {m(views, 1)} View{views > 1 ? "s" : ""}
            </Button>
          </Column>
          <Column className="px-0" xs={4}>
            <Button
              startIcon={<Share style={{ fontSize: `1rem` }} />}
              style={{ fontSize: `0.75rem` }}
              onClick={(e) => {
                if (process.env.NODE_ENV !== "development") {
                  if (check) {
                    navigator.share({ url });
                  } else {
                    setOpen(e.currentTarget);
                  }
                } else {
                  setError(new Error("Please Deploy To Share Memes."));
                }
              }}
              fullWidth
              className="py-3"
            >
              Share
            </Button>
            <ShareDropdown
              open={open}
              url={url}
              handleClose={() => setOpen(null)}
            />
          </Column>
          {activeUser.uid === uid && (
            <Column className="px-0" xs={4}>
              <Button
                startIcon={<Delete style={{ fontSize: `1rem` }} />}
                style={{ fontSize: `0.75rem` }}
                fullWidth
                className="py-3"
                onClick={async () => {
                  try {
                    const batch = db.batch();
                    batch.delete(db.collection("memes").doc(id));
                    batch.update(db.collection("users").doc(uid), {
                      likes: firebase.firestore.FieldValue.increment(
                        -1 * likes.length
                      ),
                    });
                    await batch.commit();
                    push(`/user/${activeUser.uid}`);
                    setSuccess(
                      new Error(
                        `Meme with id:${id} has been deleted successfully`
                      )
                    );
                  } catch (err) {
                    setError(err);
                  }
                }}
              >
                Delete
              </Button>
            </Column>
          )}
        </Row>
      </Card>
    </>
  );
};
export const CustomCardSkeleton = () => {
  const styles = useStyles();
  return (
    <>
      <Card component={Paper} className={styles.card}>
        <CardActionArea className="d-block text-dark text-decoration-none">
          <Box
            className={styles.main}
            style={{ backgroundColor: `transparent` }}
            position={"relative"}
          >
            <Skeleton
              animation="wave"
              width="100%"
              height="100%"
              className={styles.image}
            />
          </Box>
          <div className="container-fluid px-3">
            <div className="d-flex">
              <div className="d-flex flex-wrap">
                <span className="my-2">&nbsp;</span>
              </div>
            </div>
          </div>
          <div className={`container-fluid px-3 pb-2`}>
            <div className="mx-0 d-flex">
              <div className="p-1 w-auto">
                <Skeleton
                  animation="wave"
                  variant="circle"
                  className={styles.avatar}
                />
              </div>
              <div className="align-self-center w-100 p-1">
                <Skeleton
                  animation="wave"
                  height={20}
                  width="80%"
                  style={{ marginBottom: 6 }}
                />
                <Skeleton animation="wave" height={10} width="40%" />
              </div>
            </div>
          </div>
        </CardActionArea>
        <Row className="mx-0">
          <Column className="px-0" xs={4}>
            <Button
              startIcon={<ThumbUpAltOutlined style={{ fontSize: `1rem` }} />}
              fullWidth
              style={{ fontSize: `0.75rem` }}
              className="py-3"
            >
              <Skeleton animation="wave" height={10} width="40%" />
            </Button>
          </Column>
          <Column className="px-0" xs={4}>
            <Button
              startIcon={<Visibility style={{ fontSize: `1rem` }} />}
              style={{ fontSize: `0.75rem` }}
              fullWidth
              className="py-3"
            >
              <Skeleton animation="wave" height={10} width="40%" />
            </Button>
          </Column>
          <Column className="px-0" xs={4}>
            <Button
              startIcon={<Share style={{ fontSize: `1rem` }} />}
              style={{ fontSize: `0.75rem` }}
              fullWidth
              className="py-3"
            >
              <Skeleton animation="wave" height={10} width="40%" />
            </Button>
          </Column>
        </Row>
      </Card>
    </>
  );
};

export const CustomUserCard = ({ dp, username, likes, uid }) => {
  const classes = useUserCardStyles();
  return (
    <CardActionArea
      component={Link}
      to={`/user/${uid}`}
      className={classes.actionArea}
    >
      <Card className={classes.card}>
        <CardContent className={classes.content}>
          <div>
            <div className="row">
              <div className="col-3 pe-0">
                <CardMedia
                  className="w-100 shadow rounded-pill"
                  component="img"
                  src={dp}
                />
              </div>
              <div className="col-9">
                <Typography className={classes.title} variant={"h6"}>
                  {username}
                </Typography>
                <Typography className={classes.subtitle + " nova"}>
                  Likes: {likes || 0}
                </Typography>
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
    </CardActionArea>
  );
};
export const CustomUserCardSkeleton = () => {
  const classes = useUserCardStyles();
  return (
    <CardActionArea className={classes.actionArea}>
      <Card className={classes.card}>
        <CardContent className={classes.content}>
          <div>
            <div className="row">
              <div className="col-3 pe-0">
                <Skeleton variant="circle" width="77" height={77} />
              </div>
              <div className="col-9">
                <Skeleton
                  animation="wave"
                  height={20}
                  width="80%"
                  style={{ marginBottom: 6 }}
                />
                <Skeleton animation="wave" height={15} width="40%" />
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
    </CardActionArea>
  );
};

export const SideUserSkeleton = () => (
  <ListItem className="px-0">
    <ListItemAvatar>
      <Skeleton variant="circle" width={40} height={40} />
    </ListItemAvatar>
    <div className="w-100">
      <Skeleton
        animation="wave"
        height={20}
        width="80%"
        style={{ marginBottom: 6 }}
      />
      <Skeleton animation="wave" height={15} width="40%" />
    </div>
  </ListItem>
);
