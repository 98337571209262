import React, { createContext, useContext, useEffect, useState } from 'react';
import { useAuth } from '../context/AuthContext';

const LoginSignupForgotPopup = createContext()

const usePopup = ()=> useContext(LoginSignupForgotPopup)

const LoginSignupForgotPopupProvider = ({children}) => {
  const { isAuth } = useAuth()
  const login = useState(false);
  const signup = useState(false);
  const forgot = useState(false);
  const upload = useState(false);
  const setLogin = login[1]
  const setsignup = signup[1]
  const setforgot = forgot[1];
  const setUpload = upload[1];
  useEffect(()=>{
    if(isAuth) {
      setLogin(false)
      setsignup(false)
      setforgot(false)
    } else{
      setUpload(false)
    }
  },[isAuth,setLogin,setUpload,setsignup,setforgot])
  return (
    <LoginSignupForgotPopup.Provider value={{login,signup,forgot,upload}}>
      {children}
    </LoginSignupForgotPopup.Provider>
  );
};

export { usePopup }
export default LoginSignupForgotPopupProvider;