import React, { useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import { ReactComponent as SearchIcon } from "../../images/search-box.svg";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "0.2rem 0.5rem",
    display: "flex",
    alignItems: "center",
    width: `100%`,
    borderRadius: 30,
  },
  input: {
    marginLeft: theme.spacing(3),
    fontSize: `1.1rem`,
    flex: 1,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  iconButton: {
    "&:hover": {
      background: `transparent`,
      cursor: `default`,
    },
  },
  icon: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.primary,
    padding: 10,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
}));

const SearchBar = () => {
  const search = useRef();
  const classes = useStyles();
  const { push } = useHistory();
  const onSubmit = async (e) => {
    e.preventDefault();
    await push({
      pathname: "/results",
      search: `?tags=${encodeURIComponent(search.current.value)}`,
      state: {},
    });
    search.current.value = "";
  };
  return (
    <div className="searchbar container">
      <div className="search_inner w-100">
        <Paper
          component="form"
          onSubmit={onSubmit}
          className={classes.root + " shadow w-100"}
        >
          <InputBase
            className={classes.input}
            inputRef={search}
            placeholder="Search (Seprate Tags via comma to Search Multiple) "
            id="my_input"
            required
          />
          <div>
            <IconButton type="submit">
              <SearchIcon fill="currentColor" width={18} />
            </IconButton>
          </div>
        </Paper>
      </div>
    </div>
  );
};

export default SearchBar;
