import React, { useRef } from "react";
import Input from "../utils/Input";
import { Paper, Dialog, DialogActions } from "@material-ui/core";
import { useAuth } from "context/AuthContext";
import { useHandling } from "context/HandleContext";
import Logo from "images/Memesbit_logo.png";
import LoginButtons from "../utils/LoginButtons";
import { CustomButton } from "../utils/MaterialUICustomizedComponents";
import { usePopup } from "hooks/usePopup";
import { db } from "config/Firebase";
import { useHistory, Link } from "react-router-dom";
const SignupPopup = () => {
  const { signup } = useAuth();
  const { setSuccess, setError } = useHandling();
  const {
    signup: [open, setOpen],
    forgot: [, setForgotOpen],
    login: [, setLoginOpen],
  } = usePopup();
  const email = useRef();
  const { push } = useHistory();
  const password = useRef();
  const username = useRef();
  // const first_name = useRef();
  // const last_name = useRef();
  const cpassword = useRef();
  const get = (a) => a.current.value;
  const reject = (error) => setError(error);
  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      if (get(password) === get(cpassword)) {
        const usersCollection = db.collection("users");
        let query = usersCollection;
        query = query.where("username", "==", get(username));
        const docs = await query.get();
        if (!docs.empty)
          throw new Error("A user with corresponding username already exists");
        signup(
          get(email),
          get(password),
          get(username),
          (cred) => {
            setSuccess(new Error("Signup Successful"));
            setOpen(false);
            push(`/user/${cred.user.uid}`);
          },
          reject
        );
      } else throw new Error("Password Didn't match");
    } catch (err) {
      setError(err);
    }
  };
  return (
    <div>
      <Dialog
        open={open}
        scroll="body"
        maxWidth="md"
        onClose={() => setOpen(false)}
        aria-labelledby="form-dialog-title"
      >
        <Paper className="login_inner">
          <img src={Logo} width={250} alt="Memesbit" />
          <h3 className="font-weight-bold">Create Free Account</h3>
          <LoginButtons page="Sign Up" />
          <form onSubmit={onSubmit} className="req_form">
            <div className="w-100 mb-3 d-flex justify-content-center align-items-center">
              <div className="line"></div>
              <p className="mb-0 font-smaller px-3">OR</p>
              <div className="line"></div>
            </div>
            <div className="fields row">
              <div className="col-lg-6">
                <Input type="text" ref={username} placeholder="username" />
              </div>
              <div className="col-lg-6">
                <Input type="email" ref={email} placeholder="Email" />
              </div>
              <div className="col-lg-6">
                <Input
                  password={true}
                  type="password"
                  ref={password}
                  placeholder="Password"
                />
              </div>
              <div className="col-lg-6">
                <Input
                  password={true}
                  type="password"
                  ref={cpassword}
                  placeholder="Confirm Password"
                />
              </div>
            </div>
            <div>
              <div className="d-flex justify-content-center align-items-center">
                <span>
                  By signing up, you agree to our{" "}
                  <Link onClick={() => setOpen(false)} to="/terms">
                    Terms
                  </Link>{" "}
                  and{" "}
                  <Link onClick={() => setOpen(false)} to="/privacy">
                    Privacy Policy
                  </Link>
                </span>
              </div>
            </div>
            <button className="button_login">Sign Up</button>
          </form>
          <DialogActions className="d-flex justify-content-evenly">
            <CustomButton
              onClick={() => {
                setOpen(false);
                setForgotOpen(true);
              }}
              color="primary"
            >
              Forgot Password?
            </CustomButton>
            <CustomButton
              onClick={() => {
                setOpen(false);
                setLoginOpen(true);
              }}
              color="primary"
            >
              Login Now
            </CustomButton>
          </DialogActions>
        </Paper>
      </Dialog>
    </div>
  );
};

export default SignupPopup;
