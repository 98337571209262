import { Typography } from "@material-ui/core";
import Collapseable from "components/utils/Collapseable";
import React from "react";

const privacy = [
  {
    name: "What Information Do We Collect About You?",
    children: `We collect Personal Data about you when you provide such information
    directly to us, when third parties such as our business partners or
    service providers provide us with Personal Data about you, or when
    Personal Data about you is automatically collected in connection
    with your use of our Services. The information we gather enables us
    to improve, understand, and continue to operate the Services. In
    connection with certain aspects of the Services, we may request,
    collect and/or display some of your Personal Data. Below explains in
    greater detail the types of information that we collect about our
    users.`,
  },
  {
    name: "Information You Provide to Us",
    children: (
      <>
        <p>
          When you create account with a username and email, we handle it in the
          same way as an automatically collected username and email except that
          if you don’t allow cookies, you have to enter your email each time you
          log in. A username is, by nature, publicly posted information. On
          occasion we may run a contest or other type of promotion, and to
          enable us to award prizes to the winners as well as to comply with the
          applicable laws, we collect real names and contact information – so if
          you win a contest, we’ll ask for that information and we’ll use it
          only for those two specific purposes.
        </p>
        <p>
          Additionally, if you register for or access the Services using a Third
          Party Service (such as your social media account login credentials),
          we may receive Personal Data that you have made available to share
          through such Third Party Service, which may include, without
          limitation, your name. You may also choose to include your email
          address. You acknowledge that this information may be personal to you,
          and by creating an Account and providing Personal Data to us, you
          allow others, including us, to identify you and therefore may not be
          anonymous. We may use your contact information to send you information
          about our Services.
        </p>
      </>
    ),
  },
  {
    name: "Information you post",
    children: (
      <>
        Memesbit does not “collect” the stuff you post: images, comments, likes
        etc., and – with respect to persons in the EEA – does not “process” or
        determine any purposes for processing of any information that you
        manifestly make public. In particular, every image uploaded to Memesbit
        is public – whether uploaded directly without going through a user
        account, or uploaded via a user account – and has its own URL. No matter
        what your privacy settings are, every image can always be accessed and
        viewed by anyone who types in that exact URL. No image uploaded to
        Memesbit is ever completely hidden from public view. And user is solely
        responsive for all the content of the of their posts.
      </>
    ),
  },
  {
    name: "Information Collected Automatically",
    children: (
      <>
        <Typography variant="body1">
          We automatically receive and record information from your web browser
          or device when you interact with the Services, including when you
          search for, select, view or receive a GIF, such as your IP address,
          device ID, user query information and cookie information. This
          information is used to enable us to deliver GIFs to you, for fighting
          spam/malware, to improve the service and also to facilitate collection
          of data concerning your interaction with the Services (e.g., what
          links you have clicked on). Your IP address may be used to send you
          geographically-targeted advertisements.
        </Typography>
        <Typography variant="body1">
          Generally, the Services automatically collect usage information, such
          as the number and frequency of visitors to the Site and the Services.
          We may use this data in aggregate form, for example, as a statistical
          measure or in other anonymous forms, but not in a manner that would
          identify you personally. This type of aggregate data enables us and
          third parties authorized by us to figure out how often individuals use
          parts of the Services so that we can analyze and improve them.
        </Typography>
      </>
    ),
  },
  {
    name: "Information Collected Using Cookies",
    children: (
      <>
        <ol>
          <li>
            Cookies are pieces of text that may be provided to your computer or
            device through your web browser when you access a website. Your
            browser stores cookies in a manner associated with each website you
            visit. We may use cookies to enable our servers to recognize your
            web browser and tell us how and when you visit the Site and
            otherwise use the Services.
          </li>
          <li>
            Our cookies do not, by themselves, contain Personal Data. However,
            we may match cookies with your Personal Data to identify you, and we
            may use cookies to identify that your web browser has accessed
            aspects of the Services and may associate that information with your
            Account if you have one.{" "}
          </li>
          <li>
            We may allow third party web analytics service providers to use
            cookies or similar technologies to collect information about your
            use of the Services. We use Google Analytics, a service provided by
            Google, Inc. (“Google”) to help us understand how users use the
            Services and to enhance your experience when you use the Services.
            For more information on Google’s privacy practices, please go to
            https://policies.google.com/technologies/partner-sites and to opt
            out of data recording and analysis by Google Analytics on the
            Services, please visit https://tools.google.com/dlpage/gaoptout.
          </li>
          <li>
            Most browsers have an option for turning off the cookie feature,
            which will prevent your browser from accepting new cookies, as well
            as (depending on the sophistication of your browser software)
            allowing you to decide on acceptance of each new cookie in a variety
            of ways. We strongly recommend that you leave cookies active,
            because they enable you to take advantage of the most attractive
            features of the Services.
          </li>
          <li>
            This Privacy Policy covers our use of cookies only and does not
            cover the use of cookies by third parties. We do not control when or
            how third parties place cookies on your computer or device. For
            example, third party websites to which a link points may set cookies
            on your computer or device.{" "}
          </li>
        </ol>
      </>
    ),
  },
  {
    name: "Information Related to Advertising and the Use of Web Beacons",
    children: (
      <>
        <Typography variant="body1">
          To support and enhance the Services, we may serve advertisements
          through the Services. These advertisements are sometimes targeted and
          served to particular users, including using your IP address to send
          you geographically-targeted advertisements, and may come from third
          party companies called "ad networks." Ad networks include third party
          ad servers, ad agencies, ad technology vendors and research firms. We
          may also receive anonymized user data from our Agents (defined below),
          so we can learn and make enhancements to offer you a better
          experience. Some of these entities may use cookies, web beacons and
          other technologies to collect information about your use of the
          Services and other websites, including your IP address, web browser,
          pages viewed, time spent on pages, links clicked and conversion
          information. This information may be used by us and others to, among
          other things, tailor the Services to you (for example, by providing
          search results in your preferred language), analyze and track data,
          determine the popularity of certain content, deliver advertising and
          content targeted to your interests on our Services and other websites
          and better understand your online activity.
        </Typography>
        <Typography variant="body1">
          Advertisements served through the Services may be targeted to users
          who fit a certain general profile category and may be based on
          anonymized information inferred from information provided to us by a
          user, including Personal Data (e.g., gender or age), may be based on
          the Services usage patterns of particular users, or may be based on
          your activity on Third Party Services. We do not provide Personal Data
          to any ad networks for use outside of the Services.
        </Typography>
        <Typography variant="body1">
          To increase the effectiveness of ad delivery, we may deliver a file
          (known as a "web beacon") from an ad network to you through the
          Services. Web beacons allow ad networks to provide anonymized,
          aggregated auditing, research and reporting for us and for
          advertisers. Web beacons also enable ad networks to serve targeted
          advertisements to you when you visit other websites. Because your web
          browser must request these advertisements and web beacons from the ad
          network's servers, these companies can view, edit or set their own
          cookies, just as if you had requested a web page from their site.
        </Typography>
        <Typography variant="body1">
          We may also collect advertising identifiers from your mobile device.
          Advertising identifiers are resettable identifiers unique to your
          mobile device, and which are used in connection with advertising
          delivered to your mobile device. The IDFA (developed by Apple, Inc.
          for the iPhone) and Google Ad ID (developed by Google for Android) are
          examples of advertising identifiers.
        </Typography>
      </>
    ),
  },
  {
    name: "Aggregate Information",
    children: (
      <>
        We collect statistical information about how both unregistered and
        registered users, collectively, use the Services ("Aggregate
        Information"). Some of this information is derived from Personal Data.
        This statistical information is not Personal Data and cannot be tied
        back to you, your Account, or your web browser.
      </>
    ),
  },
  {
    name: "Public Information About You and Your Activity on the Services",
    children: (
      <>
        <Typography variant="body1">
          User profile and channel information that you choose to provide,
          including your username, contact name, GIFs you upload, information
          about yourself, your avatar/profile picture, a description of your
          channel, and links to your website or social media profiles, may be
          displayed to other users to facilitate user interaction within the
          Services. This information will be displayed to other users depending
          on your privacy settings. We will not directly reveal user email
          addresses to other users.
        </Typography>
        <Typography variant="body1">
          Some of your activity on and through the Services is public by
          default. This may include, but is not limited to, content you have
          posted publicly on the Site or otherwise through the Services. For
          registered users, all content you post publicly (or privately) will be
          associated with your account. Unregistered users will not have this
          association, but information concerning their use of the Services
          (such as what pages they have visited) may be tracked through the use
          of cookies and stored by us.
        </Typography>
        <Typography variant="body1">
          Please remember that if you choose to provide Personal Data using
          certain public features of the Services, then that information is
          governed by the privacy settings of those particular features and may
          be publicly available. Individuals reading such information may use or
          disclose it to other individuals or entities without our control and
          without your knowledge, and search engines may index that information.
          We therefore urge you to think carefully about including any specific
          information you may deem private in content that you create or
          information that you submit through the Services.
        </Typography>
      </>
    ),
  },
  {
    name: "Information Shared with Our Service Providers",
    children: (
      <>
        We employ and contract with people and other entities that perform
        certain tasks on our behalf and who are under our control (our "service
        providers"). We may need to share Personal Data with our service
        providers in order to provide products or services to you. Unless we
        tell you differently, our service providers do not have any right to use
        Personal Data or other information we share with them beyond what is
        necessary to assist us. You hereby consent to our sharing of Personal
        Data with our service providers.
      </>
    ),
  },
  {
    name: "Accessing, correcting, and limiting use of your data",
    children: (
      <>
        Because we don’t collect information about the identity of our users and
        don’t receive any information from third parties enabling us to identify
        our users, we have no means of knowing, or providing you with
        information about, whatever anonymous data about you we might have on
        our servers – unless you have a username. If you have a username, you
        may log in and access your data, correct whatever information you deem
        to be incorrect, opt out of information sharing with our advertisers, or
        delete your account. For legal reasons we may retain backup and/or
        archival copies of information prior to your corrections, amendments, or
        deletions.
      </>
    ),
  },
  {
    name: "Data Protection",
    children: (
      <>
        We take every reasonable precaution to protect the data on our servers
        from loss, misuse, unauthorized access, disclosure, alteration, or
        destruction, taking into account the risk level and the nature of the
        data. You are responsible for taking every reasonable precaution on your
        end to protect any unauthorized person from accessing your Memesbit
        account.
      </>
    ),
  },
  {
    name: "Changes to this Privacy Policy",
    children: (
      <>
        We may revise our privacy policy from time to time by posting the
        changes here. You can determine the date of the most recent changes by
        looking at the “effective date” at the top.
      </>
    ),
  },
];

const Privacy = () => {
  return (
    <div>
      <div className="container">
        <Typography variant="h1">Memesbit PRIVACY POLICY</Typography>
        <Typography variant="h5">Last Updated May 15, 2021</Typography>
        <div className="py-4">
          {privacy.map((el, i) => (
            <Collapseable {...el} i={i} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Privacy;
