import { Button } from "@material-ui/core";
import { Facebook, Twitter } from "@material-ui/icons";
import React from "react";
import { useHistory } from "react-router";
import { useAuth } from "context/AuthContext";
import { useHandling } from "context/HandleContext";
// import { useHandling } from "context/HandleContext";
import login_signup_classes, { GoogleIcon } from "css/login_signup";
import { usePopup } from "hooks/usePopup";

const LoginButtons = ({ page }) => {
  const classes = login_signup_classes();
  const { googleLogin, twitterLogin, facebookLogin } = useAuth();
  const { push } = useHistory();
  const { setSuccess, setError } = useHandling();
  const {
    login: [, setLoginOpen],
    signup: [, setSignupOpen],
  } = usePopup();
  return (
    <div className="d-flex flex-column">
      <Button
        variant="contained"
        color="primary"
        className={`${classes.button} mb-3 ${classes.facebook}`}
        startIcon={<Facebook />}
        onClick={() => {
          facebookLogin(({ user }) => {
            setLoginOpen(false);
            setSignupOpen(false);
            setSuccess(new Error(`Logged in as ${user.displayName}`));
            push(`/user/${user.uid}`);
          }, setError);
        }}
      >
        {page} With Facebook
      </Button>
      <Button
        variant="contained"
        color="primary"
        className={`${classes.button} mb-3 ${classes.google}`}
        startIcon={<GoogleIcon />}
        onClick={() => {
          googleLogin(({ user }) => {
            setLoginOpen(false);
            setSignupOpen(false);
            setSuccess(new Error(`Logged in as ${user.displayName}`));
            push(`/user/${user.uid}`);
          }, setError);
        }}
      >
        {page} With Google
      </Button>
      <Button
        variant="contained"
        color="primary"
        className={`${classes.button} mb-3 ${classes.twitter}`}
        startIcon={<Twitter />}
        onClick={() => {
          twitterLogin(({ user }) => {
            setLoginOpen(false);
            setSignupOpen(false);
            setSuccess(new Error(`Logged in as ${user.displayName}`));
            push(`/user/${user.uid}`);
          }, setError);
        }}
      >
        {page} With Twitter
      </Button>
    </div>
  );
};

export default LoginButtons;
