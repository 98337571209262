import {
  Button,
  Dialog,
  DialogTitle,
  Divider,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import PostField from "components/utils/PostField";
import { useImageStyles } from "components/utils/ImageDropzone";
import { useDropzone } from "react-dropzone";
import ImageIcon from "@material-ui/icons/Image";
import { Delete } from "@material-ui/icons";
import { useHandling } from "context/HandleContext";
import CloseIcon from "@material-ui/icons/Close";
import firebase, { db, storage } from "config/Firebase";
import { useAuth } from "context/AuthContext";
import Loader from "components/utils/Loader";
import { usePopup } from "hooks/usePopup";
const useStyles = makeStyles((theme) => ({
  title: {
    "& .MuiTypography-h6": {
      fontSize: theme.typography.h3.fontSize,
    },
  },
}));

const Upload = ({ ...props }) => {
  const classes = { ...useStyles(), ...useImageStyles() };
  const {
    upload: [open, setOpen],
  } = usePopup();
  const [loading, setLoading] = useState(false);
  const [quill, setQuill] = useState(null);
  const [images, setImages] = useState([]);
  const {
    user: { uid },
  } = useAuth();
  const { setError, setSuccess } = useHandling();
  const { acceptedFiles, fileRejections, getInputProps } = useDropzone({
    maxFiles: 1,
    accept: "image/*",
  });
  useEffect(() => {
    if (fileRejections.length > 0) setError(fileRejections?.[0]?.errors[0]);
  }, [fileRejections, setError]);
  const showThumbs = () =>
    images.map(({ type, src, database, ...rest }, i) => (
      <>
        <div className={classes.thumb} key={i}>
          <div className={classes.thumbInner}>
            {type.split("/")[0] === "video" ? (
              <video muted src={src} className={classes.img}>
                No Preview Available
              </video>
            ) : (
              <img src={src} alt={src} className={classes.img} />
            )}
          </div>
          <IconButton
            onClick={() => {
              acceptedFiles.splice(rest.i, 1);
              setImages(
                acceptedFiles.map((file, i) =>
                  Object.assign(file, { src: URL.createObjectURL(file), i })
                )
              );
              setSuccess(
                new Error(`${type.split("/")[0]} deleted successfully`)
              );
            }}
            className={classes.delete}
          >
            <Delete color="inherit" />
          </IconButton>
        </div>
      </>
    ));
  useEffect(() => {
    setImages(
      acceptedFiles.map((file, i) =>
        Object.assign(file, { src: URL.createObjectURL(file), i })
      )
    );
  }, [acceptedFiles]);
  const onSubmit = async (e = window.event) => {
    try {
      setLoading(true);
      e.preventDefault();
      const value = { likes: [] };
      const docRef = db.collection("memes").doc();
      value.id = docRef.id;
      if (!uid) throw new Error("Please Login Before Uploading Meme");
      value.user = {
        uid,
      };
      value.time = firebase.firestore.FieldValue.serverTimestamp();
      value.views = 0;
      if (quill.root.textContent) {
        value.tags = quill
          .getText()
          .split(",")
          .map((str = "") => {
            const df = str.trim().replace(/\s/g, "_");
            return str.includes("#") ? df : "#" + df;
          });
      }
      const storageRef = storage.ref(`/memes/${docRef.id}`);
      if (images.length) {
        await storageRef.put(images[0]);
        const image = await storageRef.getDownloadURL();
        value.image = image;
      } else throw new Error("Please Select an Image.");
      await docRef.set(value);
      setSuccess(new Error("Meme successfully Uploaded"));
      setLoading(false);
      onClose();
    } catch (err) {
      setLoading(false);
      setError(err);
    }
  };
  const closePopup = () => setOpen(false);
  const onClose = () => {
    closePopup();
    setImages([]);
    acceptedFiles.splice(0);
  };
  return (
    <div>
      <Dialog
        PaperProps={{ style: { width: `100%`, maxWidth: `500px` } }}
        onClose={onClose}
        open={open}
        {...props}
      >
        <div className="position-relative text-center">
          {loading && <Loader blurred />}
          <div className="d-flex justify-content-end align-items-end">
            <IconButton onClick={onClose} className="ml-auto">
              <CloseIcon />{" "}
            </IconButton>
          </div>
          <DialogTitle className={classes.title}>Create Post</DialogTitle>
          <form onSubmit={onSubmit}>
            <PostField setQuill={setQuill} />
            <Divider />
            <div className="d-flex justify-content-center align-items-center p-2">
              <input
                required
                name="Image"
                id="post_image"
                {...getInputProps()}
              />
              <Button
                variant="contained"
                color="default"
                fullWidth
                startIcon={<ImageIcon />}
                htmlFor="post_image"
                size="large"
                component="label"
              >
                Upload Meme
              </Button>
            </div>
            <Divider />
            <div>{showThumbs()}</div>
            <Divider />
            <div className="p-2">
              <Button
                variant="contained"
                color="primary"
                fullWidth
                size="large"
                type="submit"
              >
                Submit
              </Button>
            </div>
          </form>
        </div>
      </Dialog>
    </div>
  );
};

export default Upload;
