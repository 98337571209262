import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import { BrowserRouter } from "react-router-dom";
import "App.css";
import ForgotPopup from "components/Forgot/ForgotPopup";
import LoginPopup from "components/Login/LoginPopup";
import SignupPopup from "components/Signup/SignupPopup";
import Header from "components/Layout/Header";
import AuthContextProvider from "context/AuthContext";
import DataContextProvider from "context/DataContext";
import FunctionsContextProvider from "context/FunctionsContext";
import HandleContextProvider from "context/HandleContext";
import LoginSignupForgotPopupProvider from "hooks/usePopup";
import Routes from "routes/routes";
import Footer from "components/Layout/Footer";
import { useAuth } from "context/AuthContext";
import Upload from "components/Upload";
import { HelmetProvider } from "react-helmet-async";

const primary = {
  main: `#fad02c`,
  light: `#e5c100`,
  dark: `#FCC201`,
  contrastText: `#fff`,
};

class ShowVars {
  constructor(primary = {}) {
    Object.entries(primary).forEach((el) => {
      this["--primary-" + el[0]] = el[1];
    });
  }
}

const Popups = () => {
  const { isAuth } = useAuth();
  return !isAuth ? (
    <>
      <SignupPopup />
      <ForgotPopup />
      <LoginPopup />
    </>
  ) : (
    <Upload />
  );
};

function App() {
  const theme = createMuiTheme({
    palette: {
      primary,
      type: "light",
    },
    typography: {
      fontFamily: `'Open Sans', sans-serif`,
      fontWeightBold: 700,
      fontWeightLight: 300,
      fontWeightMedium: 600,
      fontWeightRegular: 400,
      h1: {
        fontSize: `3rem`,
        textTransform: `capitalize`,
        fontWeight: 700,
      },
      h2: {
        fontSize: `2.5rem`,
      },
      h3: {
        fontSize: `2rem`,
      },
      h4: {
        fontSize: `1.75rem`,
      },
      h5: {
        fontSize: `1.5rem`,
      },
      h6: {
        fontSize: `1.25rem`,
      },
      button: {
        textTransform: `capitalize`,
      },
    },
  });
  return (
    <HelmetProvider>
      <BrowserRouter>
        <ThemeProvider {...{ theme }}>
          <AuthContextProvider>
            <DataContextProvider>
              <HandleContextProvider>
                <FunctionsContextProvider>
                  <LoginSignupForgotPopupProvider>
                    <div
                      style={{
                        backgroundColor: theme.palette.background.default,
                        color: theme.palette.text.primary,
                      }}
                    >
                      <Popups />
                      <Header />
                      <div style={{ ...new ShowVars(primary) }}>
                        <Routes />
                      </div>
                      <Footer />
                    </div>
                  </LoginSignupForgotPopupProvider>
                </FunctionsContextProvider>
              </HandleContextProvider>
            </DataContextProvider>
          </AuthContextProvider>
        </ThemeProvider>
      </BrowserRouter>
    </HelmetProvider>
  );
}

export default App;
