import React, { useState,forwardRef } from "react";
import { makeStyles,TextField } from "@material-ui/core";
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

const useStyles = makeStyles(theme=> ({
  input: {
    width: `100%`,
    background: `#b3b2b250`,
    outline: `0`,
    fontSize: `0.875rem`,
    borderRadius: `10px`,
    color: `var(--color_1)`,
    transition: `background 0.3s`,
    lineHeight: `50px`,
    overflow: `hidden`,
    border: `1px solid #0000`,
    "& input,& div,& textarea": {
      height: `100%`,
      padding: 0
    },
    "& input,& textarea":{
      padding: `12px`,
    }
    ,
    "& ::placeholder": {
      textTransform: `capitalize`
    }
  }
}))

const Input = ({ password=false, type,...rest },ref) => {
  const [passType, setPassType] = useState(type);
  const { input } = useStyles()
  const setType = ()=>{
    if(password){
      setPassType(pt=>pt === "password" ? "text" : "password")
    }
  }
  return (
    <div className="field">
      <TextField inputRef={ref} type={passType} {...rest} className={input} />
      {password ? (
        <span onClick={setType} className="d-inline-block position-absolute pass_wrapper" >
          {passType === "text" && password ? <VisibilityIcon style={{fill:"var(--primary-main)"}} /> : <VisibilityOffIcon style={{fill:"#999"}} />}
          {/*  style={{fill: passType === "text" && password ? "var(--primary-main)" : "#999"} */}
        </span>
      ) : (
        ""
      )}
    </div>
  );
};

export default forwardRef(Input);
