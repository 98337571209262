import React, { Component, createContext, useContext } from "react";
import { AuthContext } from "./AuthContext";
const DataContext = createContext();

export const useData = () => useContext(DataContext);
class DataContextProvider extends Component {
  static contextType = AuthContext;
  state = {
    pageLoaded: false,
    allLoaded: false,
    presistant: this.context.presistant,
  };
  componentDidMount() {
    window.addEventListener("load", () => this.setState({ pageLoaded: true }));
  }
  componentDidUpdate(pp, ps) {
    console.log(this.state.presistant);
    if (this.state.presistant !== this.context.presistant)
      this.setState({ presistant: this.context.presistant });
  }
  UNSAFE_componentWillUpdate(prevProps, prevState) {
    const { pageLoaded, allLoaded, presistant } = prevState;

    if (presistant && pageLoaded && !allLoaded) {
      this.setState({ allLoaded: true });
      const loaders = document.querySelectorAll(".loader_wrapper");
      loaders.forEach((el) => el.classList.add("hide"));
      setTimeout(() => {
        loaders.forEach((el) => el.remove());
      }, 1000);
    }
  }
  get = (a) => a.current.value;
  render() {
    return (
      <DataContext.Provider value={{}}>
        {this.props.children}
      </DataContext.Provider>
    );
  }
}

export default DataContextProvider;
