import {
  Avatar,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
  Typography,
} from "@material-ui/core";
import {
  Column,
  Row,
  CustomButton,
  CustomCard,
} from "components/utils/MaterialUICustomizedComponents";
import Fab from "@material-ui/core/Fab";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Chip from "@material-ui/core/Chip";
import { useHandling } from "context/HandleContext";
import ShareDropdown from "components/utils/ShareDropdown";
import LinkIcon from "@material-ui/icons/Link";
import { ThumbUpAlt, ThumbUpAltOutlined, Visibility } from "@material-ui/icons";
import { useAuth } from "context/AuthContext";
import firebase, { db } from "config/Firebase";
import { social } from "components/utils/ShareDropdown";
import Loader from "components/utils/Loader";
import { SideUserSkeleton } from "components/utils/MaterialUICustomizedComponents";
import { CustomCardSkeleton } from "components/utils/MaterialUICustomizedComponents";
import { renderToString } from "react-dom/server";
import { Helmet, HelmetProvider } from "react-helmet-async";

const useStyles = makeStyles({
  button: {
    "&:hover": {
      backgroundColor: `transparent`,
    },
  },
  size: {
    fontSize: `1.5rem`,
    "& *": {
      fontSize: `1.5rem`,
    },
  },
});

const Details = () => {
  const classes = useStyles();
  const { setError, setSuccess } = useHandling();
  const { activeUser = {}, isAuth } = useAuth();
  const [loading, setLoading] = useState(true);
  const url = window.location.toString();
  const { id } = useParams();
  const [meme, setMeme] = useState({});
  const [relatedmemes, setrelatedmemes] = useState([]);
  const [relatedmemesLoaded, setrelatedmemesLoaded] = useState(false);
  const [popularUsers, setPopularUsers] = useState([]);
  const [popularUsersLoaded, setPopularUsersLoaded] = useState(false);
  const [user, setUser] = useState({});
  const [userLoaded, setUserLoaded] = useState(false);
  const [LikeIcon, setIcon] = useState(ThumbUpAltOutlined);
  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (meme.likes?.includes(activeUser.uid)) setIcon(ThumbUpAlt);
    else setIcon(ThumbUpAltOutlined);
  }, [activeUser.uid, meme.likes]);
  useEffect(() => {
    setLoading(true);
    const unsub = db
      .collection("memes")
      .doc(id)
      .onSnapshot((doc) => {
        const meme = doc.data();
        setMeme(meme);
        const app = (
          <HelmetProvider>
            <Helmet>
              <title>Meme Details</title>
              <meta property="og:title" content={`Meme Details`} />
              <meta property="og:url" content={window.location.toString()} />
              <meta property="og:description" content={meme?.tags?.join(",")} />
              <meta property="og:type" content="article" />
              <meta property="og:image" content={meme.image} />
            </Helmet>
          </HelmetProvider>
        );
        renderToString(app);
        setLoading(false);
      });
    return () => unsub();
  }, [id]);
  useEffect(() => {
    setrelatedmemesLoaded(false);
    const fetchRelated = () => {
      if ("tags" in meme) {
        return db
          .collection("memes")
          .where("tags", "array-contains-any", meme.tags)
          .where("id", "!=", meme.id)
          .onSnapshot((docs) => {
            const reads = docs.docs.map((el) => el.data());
            setrelatedmemes(reads);
            setrelatedmemesLoaded(true);
          });
        } else{
        setrelatedmemesLoaded(true);
      }
    };
    const unsub = fetchRelated();
    return () => unsub?.();
  }, [meme]);
  useEffect(() => {
    const fetchPopularUsers = () => {
      return db
        .collection("users")
        .orderBy("likes", "desc")
        .limit(3)
        .onSnapshot(
          (docs) => {
            const pu = docs.docs.map((doc) => doc.data());
            setPopularUsers(pu);
            setPopularUsersLoaded(true);
          },
          (err) => {
            setError(err);
            console.error(err);
          }
        );
    };
    const unsubPopular = fetchPopularUsers();
    return () => unsubPopular();
  }, [setError]);
  useEffect(() => {
    const fetchUser = async () => {
      if (meme.user?.uid) {
        const user = (
          await db.collection("users").doc(meme.user?.uid).get()
        ).data();
        setUser(user);
        setUserLoaded(true);
      }
    };
    fetchUser();
  }, [meme.user]);
  const likeUnlikeMeme = async () => {
    try {
      if (isAuth) {
        const method = meme.likes.includes(activeUser.uid)
          ? "arrayRemove"
          : "arrayUnion";
        const userRef = db.collection("users").doc(meme.user.uid);
        const batch = db.batch();
        batch.update(db.collection("memes").doc(id), {
          likes: firebase.firestore.FieldValue[method](activeUser.uid),
        });
        batch.update(userRef, {
          likes: firebase.firestore.FieldValue.increment(
            meme.likes.includes(activeUser.uid) ? -1 : 1
          ),
        });
        await batch.commit();
      } else {
        throw new Error("Please Login to Like the item.");
      }
    } catch (err) {
      console.error(err);
      setError(err);
    }
  };
  function m(n, a = 1) {
    let x = ("" + n).length,
      p = Math.pow,
      d = p(10, a);
    x -= x % 3;
    return Math.round((n * d) / p(10, x)) / d + " kMGTPE"[x / 3];
  }
  async function copyToClipboard(text = "") {
    await navigator.clipboard.writeText(text);
    setSuccess(new Error("Text Copied to Clipboard"));
  }
  return (
    <div style={{ minHeight: `100vh` }}>
      {loading && <Loader fullPage />}
      <div className="mt-5 container">
        <div className="row">
          <div className="col-lg-4">
            <div className="row">
              <div className="col-lg-12">
                {userLoaded ? (
                  <div className="d-flex flex-items-center mb-2 mb-md-0">
                    <img
                      src={user.dp}
                      alt={user.username}
                      size={45}
                      height={45}
                      width={45}
                      className="avatar-user rounded-circle me-3"
                    />
                    <div className="flex-auto">
                      <h1 className="h5 lh-condensed mb-0">
                        <Link
                          to={"/user/" + user.uid}
                          className="text_primary text-decoration-none"
                        >
                          {user.username}
                        </Link>
                      </h1>
                      <h6 className="d-flex flex-items-center flex-wrap">
                        <p className="color-text-tertiary mb-0 mr-3">
                          {user.likes} Likes
                        </p>
                      </h6>
                    </div>
                  </div>
                ) : (
                  <SideUserSkeleton />
                )}
                <List component="nav" className="w-100">
                  <ListItem className="px-0" component="h3">
                    <Typography component="span" variant="h5">
                      Most Liked Users
                    </Typography>
                  </ListItem>
                  {popularUsersLoaded
                    ? popularUsers.map((user) => {
                        return (
                          <ListItem
                            className="px-0"
                            component={Link}
                            to={`/user/${user.uid}`}
                          >
                            <ListItemAvatar>
                              <Avatar src={user.dp} alt={`@${user.username}`}>
                                {user.username?.[0]}
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              primary={`${user.username}`}
                              secondary={`${m(user.likes)} Likes`}
                            />
                          </ListItem>
                        );
                      })
                    : [1, 2, 3].map(() => <SideUserSkeleton />)}
                </List>
              </div>
            </div>
          </div>
          <div className="col-lg-8">
            <Row>
              <Column lg={6}>
                <div className="card_image shadow rounded">
                  <img
                    src={meme.image}
                    className="w-100"
                    alt={`Meme By @${meme.user?.uid}`}
                  />
                </div>
              </Column>
              <Column lg={6}>
                <div className="my-2 px-md-3">
                  {meme.tags?.map((el) => (
                    <Chip
                      color="primary"
                      className="me-2 mb-2 text-white"
                      avatar={
                        <Avatar className="text-white" children={el[0]} />
                      }
                      label={el}
                      variant="default"
                    />
                  ))}
                </div>
                <div className="container-fluid px-0 px-sm-3">
                  <Row className="mx-0">
                    {
                      <Column className="px-0" xs={12}>
                        <Button
                          onClick={likeUnlikeMeme}
                          color={
                            meme.likes?.includes(activeUser.uid)
                              ? "primary"
                              : "default"
                          }
                          startIcon={<LikeIcon />}
                          fullWidth
                          disableRipple
                          className={`py-3 justify-content-start ${classes.button}`}
                          disabled={activeUser.uid === meme.user?.uid}
                        >
                          {m(meme.likes?.length, 1)} Like
                          {meme.likes?.length > 1 ? "s" : ""}
                        </Button>
                      </Column>
                    }
                    <Column className="px-0" xs={12}>
                      <Button
                        startIcon={<LinkIcon />}
                        onClick={(e) => {
                          copyToClipboard(window.location.toString());
                        }}
                        fullWidth
                        disableRipple
                        className={`py-3 justify-content-start ${classes.button}`}
                      >
                        Copy Link
                      </Button>
                      <ShareDropdown
                        open={open}
                        url={url}
                        handleClose={() => setOpen(null)}
                      />
                    </Column>
                    <Column className="px-0" xs={12}>
                      <CustomButton
                        startIcon={<Visibility />}
                        fullWidth
                        disableRipple
                        className={`py-3 justify-content-start ${classes.button}`}
                      >
                        {" "}
                        {m(meme.views, 1)} View{meme.views > 1 ? "s" : ""}{" "}
                      </CustomButton>
                    </Column>
                    <Column className="px-1" xs={12}>
                      <Typography className="mb-3" variant="h4">
                        Share It!
                      </Typography>
                      {social.map((el) => {
                        return (
                          <Fab
                            size="small"
                            color="secondary"
                            component="a"
                            href={el.url(window.location.toString())}
                            target="_blank"
                            rel="norefferer"
                            className="me-2 text-white"
                            style={{ backgroundColor: el.color }}
                          >
                            {" "}
                            <el.Icon />{" "}
                          </Fab>
                        );
                      })}
                    </Column>
                  </Row>
                </div>
              </Column>
            </Row>
          </div>
        </div>
        <div className="my-3">
          <Typography variant="h2">Related Memes</Typography>
        </div>
        {relatedmemesLoaded ? (
          relatedmemes.length ? (
            <Row>
              {relatedmemes?.map((el) => (
                <Column
                  lg={4}
                  className="px-sm-2 px-1 py-sm-3 py-2 position-relative"
                >
                  <CustomCard {...el} />
                </Column>
              ))}
            </Row>
          ) : (
            <Typography variant="h5">No related memes to show</Typography>
          )
        ) : (
          [1, 2, 3].map(() => (
            <Column
              lg={4}
              className="px-sm-2 px-1 py-sm-3 py-2 position-relative"
            >
              <CustomCardSkeleton />
            </Column>
          ))
        )}
      </div>
    </div>
  );
};

export default Details;
