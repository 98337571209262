import { Typography } from "@material-ui/core";
import { Column } from "components/utils/MaterialUICustomizedComponents";
import { Row } from "components/utils/MaterialUICustomizedComponents";
import React from "react";
import src from "../../images/qr.jpg";
const Donate = () => {
  return (
    <div
      className="container d-flex justify-content-center align-items-center"
      style={{ minHeight: `100vh` }}
    >
      <Row>
        <Column md={6} className="d-flex flex-column justify-content-center">
          <Typography className="fw-bold mb-2" variant="h1">
            Donate
          </Typography>
          <Typography className="mb-3 fw-light" variant="h6">
            While we are trying to make you laugh through this ups and downs,
            you should share this happiness with us through some BTC... <br />{" "}
            Donate and check your withdraw is working properly
          </Typography>
        </Column>
        <Column md={6}>
          <div className="text-center">
            <img
              src={src}
              width="100%"
              style={{ maxWidth: 300 }}
              alt="Donate"
            />
          </div>
        </Column>
      </Row>
    </div>
  );
};

export default Donate;
