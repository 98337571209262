import React, { useRef } from "react";
import "./login.css";
import Input from "../utils/Input";
import { Paper } from "@material-ui/core";
import { useAuth } from "../../context/AuthContext";
import { useHandling } from "../../context/HandleContext";
import { CustomButton } from "../utils/MaterialUICustomizedComponents";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import { usePopup } from "../../hooks/usePopup";
import LoginButtons from "../utils/LoginButtons";
import Logo from "../../images/Memesbit_logo.png";
import { useHistory, Link } from "react-router-dom";
const LoginPopup = () => {
  const { login } = useAuth();
  const { push } = useHistory();
  const {
    login: [open, setOpen],
    forgot: [, setForgotOpen],
    signup: [, setSignupOpen],
  } = usePopup();
  const { setError, setSuccess } = useHandling();
  const email = useRef();
  const password = useRef();
  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await login(email.current.value, password.current.value);
      setSuccess(new Error("Login Successful"));
      push(`/user${res.user.uid}`);
      setOpen(false);
    } catch (err) {
      setError(err);
    }
  };
  return (
    <div>
      <Dialog
        open={open}
        scroll="body"
        maxWidth="md"
        onClose={() => setOpen(false)}
        aria-labelledby="form-dialog-title"
      >
        <Paper className="login_inner">
          <img src={Logo} width={250} alt="Memesbit" />
          <h1 className="font-weight-bold mt-4">Login to Your Account</h1>
          <div>
            <LoginButtons page="Login" />
          </div>
          <form onSubmit={onSubmit} className="login_form">
            <div className="w-100 mb-3 d-flex justify-content-center align-items-center">
              <div className="line"></div>
              <p className="mb-0 font-smaller px-3">OR</p>
              <div className="line"></div>
            </div>
            <div className="fields">
              <Input type="email" ref={email} placeholder="Email" />
              <Input
                password={true}
                ref={password}
                type="password"
                placeholder="Password"
              />
            </div>
            <div>
              <div className="d-flex justify-content-center align-items-center">
                <span>
                  By Logging in, you agree to our{" "}
                  <Link onClick={() => setOpen(false)} to="/terms">
                    Terms
                  </Link>{" "}
                  and{" "}
                  <Link onClick={() => setOpen(false)} to="/privacy">
                    Privacy Policy
                  </Link>
                </span>
              </div>
            </div>
            <button className="button_login">Login</button>
          </form>
          <DialogActions className="d-flex justify-content-evenly">
            <CustomButton
              onClick={() => {
                setOpen(false);
                setForgotOpen(true);
              }}
              color="primary"
            >
              Forgot Password?
            </CustomButton>
            <CustomButton
              onClick={() => {
                setOpen(false);
                setSignupOpen(true);
              }}
              color="primary"
            >
              SignUp Now
            </CustomButton>
          </DialogActions>
        </Paper>
      </Dialog>
    </div>
  );
};

export default LoginPopup;
