import React, { useRef } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { Button, TextField } from "@material-ui/core";
import firebase, { auth } from "config/Firebase";
import { useHandling } from "context/HandleContext";
import { CustomButton } from "./MaterialUICustomizedComponents";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PasswordPopup = ({ open, setOpen, submitOnRequired }) => {
  const passwordRef = useRef();
  const { setError, setLoading } = useHandling();
  const handleClose = () => {
    setOpen(false);
  };
  const user = auth.currentUser || {};
  const handleSubmit = async (e) => {
    setOpen(false);
    try {
      e.preventDefault();
      if (e.target.checkValidity()) {
        setLoading(true);
        const credential = firebase.auth.EmailAuthProvider.credential(
          user.email,
          passwordRef.current.value
        );
        // Now you can use that to reauthenticate
        await user.reauthenticateWithCredential(credential);
        submitOnRequired();
      } else {
        throw new Error("Please Enter a Password");
      }
    } catch (err) {
      setError(err);
    }
  };
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="form-dialog-title"> {user.displayName} </DialogTitle>
      <form onSubmit={handleSubmit} action="">
        <DialogContent>
          <DialogContentText>
            Confirm you want to change the details of Your Account. Please Enter
            Your Password to Continue
          </DialogContentText>
          <TextField
            autoFocus
            id="name"
            label="Password"
            type="password"
            variant="filled"
            inputRef={passwordRef}
            fullWidth
            required
          />
        </DialogContent>
        <DialogActions>
          <CustomButton onClick={handleClose} color="error">
            Cancel
          </CustomButton>
          <Button type="submit" variant="contained" color="primary">
            Continue
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default PasswordPopup;
