import { Menu, MenuItem, useTheme, withStyles } from "@material-ui/core";
import { useHandling } from "context/HandleContext";
import React from "react";
import { Facebook, LinkedIn, WhatsApp, Twitter } from "@material-ui/icons";
import { GoogleIcon as Google } from "css/login_signup";
import Color from "color";

const icons = { Facebook, LinkedIn, WhatsApp, Twitter, Google };

const items = [
  {
    name: "Facebook",
    url: (url) => `https://facebook.com/sharer/sharer.php?u=${url}`,
    color: `#4267B2`,
  },
  {
    name: "Twitter",
    url: (url) => `https://twitter.com/intent/tweet/?text=${url}`,
    color: `#1DA1F2`,
  },
  {
    name: "LinkedIn",
    url: (url) => `https://www.linkedin.com/shareArticle?mini=true&url=${url}`,
    color: `#2867B2`,
  },
  {
    name: "WhatsApp",
    url: (url) => `whatsapp://send?text=${url}`,
    color: `#4fce5d`,
  },
];

export const social = items.map((el) => ({ ...el, Icon: icons[el.name] }));

const CustomMenuItem = withStyles((theme) => ({
  root: {
    color: (props) => props.color,
    backgroundColor: (props) =>
      Color(`${props.color}`).lighten(0.7).fade(0.6).toString(),
    "&:hover": {
      color: (props) => props.color,
      backgroundColor: (props) =>
        Color(`${props.color}`).lighten(0.6).fade(0.6).toString(),
    },
  },
}))(MenuItem);
const ShareDropdown = ({ url, open, handleClose }) => {
  const { setSuccess } = useHandling();
  const theme = useTheme();
  async function copyToClipboard(text = "") {
    await navigator.clipboard.writeText(text);
    setSuccess(new Error("Text Copied to Clipboard"));
  }
  return (
    <Menu
      component="nav"
      onClose={handleClose}
      anchorEl={open}
      keepMounted
      open={Boolean(open)}
    >
      <CustomMenuItem
        onClick={() => {
          copyToClipboard(url);
          handleClose();
        }}
        button
        color={theme.palette.primary.main}
      >
        {" "}
        Copy to Clipboard{" "}
      </CustomMenuItem>
      {social.map((el) => (
        <CustomMenuItem
          target="_blank"
          onClick={() => handleClose()}
          component="a"
          href={el.url(url)}
          button
          color={el.color}
        >
          {" "}
          <el.Icon className="me-2" /> {el.name}{" "}
        </CustomMenuItem>
      ))}
    </Menu>
  );
};

export default ShareDropdown;
