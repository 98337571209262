import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { useAuth } from "context/AuthContext";
import Profile from "components/Profile";
import Settings from "components/Settings";
import Home from "components/Home";
import Verification from "components/Verification";

import { usePopup } from "hooks/usePopup";
import Details from "components/Details";
import Donate from "components/Donate";
import Terms from "components/Terms";
import Privacy from "components/Privacy";
import Search from "components/Search";
const PrivateRoutes = ({ component: Comp, ...rest }) => {
  const { isAuth, presistant } = useAuth();
  console.log({ isAuth, presistant });
  const {
    login: [, setOpen],
  } = usePopup();
  return (
    <Route
      {...rest}
      component={(props) => {
        if (isAuth || !presistant) {
          setOpen(false);
          return <Comp {...props} />;
        } else {
          setOpen(true);
          return <Redirect to="/" />;
        }
      }}
    />
  );
};

const PublicRoutes = ({ component: Comp, ...rest }) => {
  const { isAuth } = useAuth();
  return (
    <Route
      {...rest}
      component={(props) =>
        rest.restricted ? (
          isAuth ? (
            <Redirect to="/" />
          ) : (
            <Comp {...props} />
          )
        ) : (
          <Comp {...props} />
        )
      }
    />
  );
};

const Routes = () => {
  const { activeUser, user } = useAuth();
  return activeUser.uid &&
    !user.emailVerified &&
    activeUser.type !== "facebook" &&
    activeUser.type !== "twitter" ? (
    <Switch>
      <Route path="*" component={Verification} exact />
    </Switch>
  ) : (
    <Route
      render={() => {
        window.scrollTo({ top: 0, behavior: `smooth` });
        return (
          <Switch>
            <PublicRoutes component={Profile} path="/user/:uid" exact />
            <PublicRoutes component={Home} path="/" exact />
            <PrivateRoutes
              component={Settings}
              path="/account/:profile"
              exact
            />
            <PublicRoutes component={Terms} path="/terms" exact />
            <PublicRoutes component={Privacy} path="/privacy" exact />
            <PublicRoutes component={Details} path="/memes/:id" exact />
            <PublicRoutes component={Donate} path="/donate" exact />
            <PublicRoutes component={Search} path="/results" exact />
          </Switch>
        );
      }}
    />
  );
};

export default Routes;
