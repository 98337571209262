import { Button } from "@material-ui/core";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
// import { auth } from "../../config/Firebase";
import { useAuth } from "context/AuthContext";
import { useFunctions } from "context/FunctionsContext";
import { useHandling } from "context/HandleContext";
import PasswordPopup from "../utils/PasswordPopup";
import ProfileContainer from "../utils/ProfileContainer";
import ProfilePicUploader from "../utils/ProfilePicUploader";
// import MaskedInput from 'react-text-mask';
import { auth, db } from "config/Firebase";

const Settings = () => {
  const { showTags, Refs, GetValues } = useFunctions();
  const { profile } = useParams();
  const [open, setOpen] = useState(false);
  // const [values, setValues] = useState({});
  const { activeUser = {}, user = {} } = useAuth();
  const { setError, setSuccess, setLoading } = useHandling();
  const profileInputs = {
    settings: {
      inputs: [
        // { name: "first_name" },
        // { name: "last_name" },
        { name: "username" },
        // { name: "bio", multiline: true, rows: 4 },
        // { name: "phone_number",InputProps:{inputComponent: TextMaskCustom,startAdornment: <InputAdornment style={{transform: `translateX(10px)`}} position="start">+1</InputAdornment>,} },
      ],
      // radios: [
      //   {name: "gender",options: ["male","female"],}
      // ],
      image: true,
    },
    emails: {
      inputs: [{ name: "email", type: { name: "email" } }],
      passwordRequired: true,
    },
    password: {
      inputs: [
        { name: "password", type: { name: "password" } },
        { name: "confirm_password", type: { name: "password" } },
      ],
      passwordRequired: true,
    },
  };
  const active = profileInputs[profile];
  const [inputRefs] = useState(new Refs([], active.inputs));
  const [radioValues] = useState({});
  // class SelectValues {
  //   constructor(selects=[]){
  //     selects.map(el=>{
  //       this[el.name] = activeUser[el.name] || ""
  //     })
  //   }
  // }
  // useEffect(()=>{
  //   setRadios({...new SelectValues(active.radios)})
  // },[activeUser]);
  const submitOnRequired = async () => {
    try {
      const values = { ...new GetValues(inputRefs), ...radioValues };
      const usersCollection = db.collection("users");
      const userDoc = usersCollection.doc(user.uid);
      if (profile === "emails" && values.email) {
        await user.updateEmail(values.email);
        await userDoc.update({ email: values.email });
        setSuccess(new Error("Email has been Updated Successfully"));
        auth
          .signOut()
          .then(() =>
            setSuccess(
              new Error(
                "You will be redirected to login. Please Login and Verify your email"
              )
            )
          );
      } else if (profile === "password" && values.password) {
        await user.updatePassword(values.password);
        setSuccess(new Error("Password has been Updated Successfully"));
      } else if (values) {
        let query = usersCollection;
        query = query.where("uid", "!=", user.uid);
        query = query.where("username", "==", values.username);
        const docs = await query.get();
        if (!docs.empty)
          throw new Error("A user with corresponding username already exists");
        await user.updateProfile({
          photoURL: values.dp,
          displayName: values.username,
        });
        await userDoc.update({ ...values });
        setSuccess(new Error("Account Details Updated Successfully"));
      } else {
        return;
      }
      setLoading(false);
    } catch (err) {
      setError(err);
    }
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      if (profile === "password") {
        const values = { ...new GetValues(inputRefs) };
        if (!(values.password === values.confirm_password)) {
          throw new Error("Passwords Doesn't Match");
        }
      }
      if (active.passwordRequired) {
        setOpen(true);
      } else {
        submitOnRequired();
      }
    } catch (err) {
      setError(err);
    }
  };
  return (
    <ProfileContainer user={activeUser}>
      <div className="row">
        <form action="/" onSubmit={onSubmit} className="col-md-6">
          {showTags(inputRefs, activeUser, true, 12)}
          {/* {showRadios(active.radios, radioValues, setRadios,12)} */}
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className="text-white"
          >
            Submit
          </Button>
        </form>
        {active.image && <ProfilePicUploader user={activeUser} editable />}
        <PasswordPopup
          open={open}
          setOpen={setOpen}
          submitOnRequired={submitOnRequired}
        />
      </div>
    </ProfileContainer>
  );
};

export default Settings;
