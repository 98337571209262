import { makeStyles, IconButton } from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useHandling } from "context/HandleContext";

const useStyles = makeStyles((theme) => ({
  dropzone: {
    cursor: "pointer",
    border: (props) =>
      `3px dashed ${props.isDragActive ? theme.palette.primary.main : "#999"}`,
    padding: "2rem",
    outline: "none",
  },
  thumbsContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 16,
  },
  thumb: {
    display: "inline-flex",
    borderRadius: 6,
    border: `1px solid #eaeaea`,
    marginBottom: 8,
    marginRight: 8,
    width: 120,
    height: 120,
    padding: 4,
    boxSizing: "border-box",
    position: `relative`,
  },
  thumbInner: {
    display: "flex",
    minWidth: 0,
    overflow: "hidden",
  },
  img: {
    display: "block",
    width: "auto",
    height: "100%",
  },
  delete: {
    top: 0,
    right: 0,
    position: `absolute`,
    "&:hover": {
      // backgroundColor: theme.palette.primary.light + " !important",
      color: theme.palette.primary.main + " !important",
      opacity: 1 + " !important",
    },
  },
}));

const ImageDropzone = ({
  setImages,
  docRef,
  type = "image/*",
  name,
  size = 1,
}) => {
  const [files, setFiles] = useState([]);
  const { setError } = useHandling();
  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    isDragActive,
    getInputProps,
  } = useDropzone({
    maxFiles: size,
    accept: type,
  });
  const classes = useStyles({ isDragActive });
  useEffect(() => {
    setImages(acceptedFiles);
    setFiles(
      acceptedFiles.map((file, i) =>
        Object.assign(file, { src: URL.createObjectURL(file), i })
      )
    );
  }, [acceptedFiles, setImages]);
  useEffect(() => {
    if (fileRejections.length > 0) setError(fileRejections?.[0]?.errors[0]);
  }, [fileRejections, setError]);
  const showThumbs = () =>
    files.map(({ type, src, database, ...rest }, i) => (
      <>
        <div className={classes.thumb} key={i}>
          <div className={classes.thumbInner}>
            {type.split("/")[0] === "video" ? (
              <video muted src={src} className={classes.img}>
                No Preview Available
              </video>
            ) : (
              <img src={src} alt={src} className={classes.img} />
            )}
          </div>
          <IconButton className={classes.delete}>
            <Delete color="inherit" />
          </IconButton>
        </div>
      </>
    ));

  return (
    <div className="">
      <div {...getRootProps({ className: classes.dropzone })}>
        <input name={name} {...getInputProps()} />
        <p>
          Drag 'n' drop some {type.split("/*").join(",")}
          {size > 1 && "s"} here, or click to select{" "}
          {type.split("/*").join(",")}
          {size > 1 && "s"}
        </p>
        <em>
          ({size} {type.split("/*").join("")}
          {size > 1 && "s"} are the maximum number of{" "}
          {type.split("/*").join(",")}
          {size > 1 && "s"} you can drop here)
        </em>
      </div>
      <aside className={classes.thumbsContainer}>
        <ul className="list-unstyled">{showThumbs()}</ul>
      </aside>
    </div>
  );
};

export { useStyles as useImageStyles };

export default ImageDropzone;
